import React from 'react'
const dataRegex = /\{(.*)\}/

export const T = (text, data = null) => {
  const rawText = (text?.text || '').split(' ')
  const value = rawText.map((v, i) => {
    if (typeof v === 'object') {
      return <span key={i}>{v}</span>
    }
    if (v.trim() === '\\n') {
      return <br />
    }
    if (typeof v === 'string') {
      return v
        .replace(/<a[^>]*href=["']?([^"' >]+)['"]?[^>]*>[^<]*<\/a>/gi, '$1')
        .split(/(?=http)| /)
        .map((item) => {
          if (item.startsWith('http')) {
            return (
              <a
                href={item}
                target='_blank'
                rel='noopener noreferrer'
                onClick={(e) => e.stopPropagation()}
              >
                lien
              </a>
            )
          }
          if (dataRegex.test(item)) {
            return data ? data + ' ' : ''
          } else {
            return item + ' '
          }
        })
    }
    return v
  })
  return <React.Fragment>{value}</React.Fragment>
}
