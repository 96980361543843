import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'


const TranslationsContext = createContext({
  content: {},
  appTexts: {},
})

export const TranslationModuleProvider = ({ children, lang, moduleContent }) => {
  const [moduleTrads, setModuleTads] = useState()
  const getModuleTranslations = async () => {
    const trads = await fetch(`https://ct-campaigns.civitimeapp.com/texts/modules/quiz/${lang}.json`)
      .then(r => {
        if (r.status === 404) {
          return null
        }
        return r.json()
      })
    setModuleTads({ ...trads, moduleContent: moduleContent })
  }
  useEffect(() => {
    getModuleTranslations()
  }, [])
  return <TranslationsContext.Provider value={{
    content: moduleTrads,
    lang: lang
  }}>
    {children}
  </TranslationsContext.Provider>
}

const splitParams = /([[{].*?[\]}])/
export const useTranslations = () => {
  const { content, lang, setLang, derived } = useContext(TranslationsContext)
  const t = useCallback(
    (key, data) => toText(key, data, content),
    [content],
  )
  return {
    lang,
    derived,
    content,
    setLang,
    t: t,
    text: (...params) => t(...params).join(''),
  }
}
export const TContent = (({ children, path, data }) => {
  const { t } = useTranslations()
  const rawText = t(path, data || {}).filter(Boolean)

  const value = rawText
    .map((v, i) => {
      if (typeof v === 'object') {
        return <span key={i}>{v}</span>
      }
      if (typeof v === 'string') {
        return <React.Fragment key={v}>{v
          .replace(/<a[^>]*href=["']?([^"' >]+)['"]?[^>]*>[^<]*<\/a>/gi, '$1')
          .split(/(?=http)| /)
          .map(item => {
            if (item.startsWith('http')) {
              return <a href={item} target='_blank' rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
              >{t('app.challenges.link')}</a>
            } else {
              return item + ' '
            }

          })}</React.Fragment>
      }
      return v
    })
  return <>
    {value || children}
  </>
})
export const T = TContent

export const useTranslator = () => {
  const { content, lang, setLang, derived } = useContext(TranslationsContext)
  const t = useCallback(
    (key, data) => {
      return toText(key, data, content) },
    [content],
  )
  return {
    lang,
    derived,
    content,
    setLang,
    t: t,
    text: (...params) => t(...params).join('').replace(isMSDReg, ''),
  }
}

function toText (initialKey, data, content) {
  try {
    const key = initialKey.replace(/^text\./i, '')
    const rawValue = getValue(key, content)
    return rawValue
      .split(splitParams)
      .map(s => {
        if (s[0] === '{' || s[0] === '[') {
          return data[s.slice(1, -1)] || ''
        }
        return s
      })
  } catch (e) {
    return  [initialKey]
  }
}

const splitter = /\./

function getValue (key, obj) {
  const fallback = key
  if (!key) return fallback
  return key
    .split(splitter)
    .reduce((acc, k) => acc && acc[k], obj)
    ?.toString() ?? fallback
}
