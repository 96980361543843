import React    from 'react'
import { View } from 'react-native'
import styled   from '@emotion/native'

import { colors }              from '../../configs/colors'
import { useIsActive }         from '../../hooks/useIsActive'
import Square                  from '../../../assets/teamIcon/square.svg'
import First                   from '../../../assets/rankIcon/first.svg'
import Second                  from '../../../assets/rankIcon/second.svg'
import Third                   from '../../../assets/rankIcon/third.svg'
import Triangle                from '../../../assets/teamIcon/triangle.svg'
import Round                   from '../../../assets/teamIcon/round.svg'
import Fragment1               from '../../../assets/fragments/1.svg'
import Fragment2               from '../../../assets/fragments/2.svg'
import Fragment3               from '../../../assets/fragments/3.svg'
import Fragment4               from '../../../assets/fragments/4.svg'
import Fragment5               from '../../../assets/fragments/5.svg'
import Fragment6               from '../../../assets/fragments/6.svg'
import { BoldText, Cartridge } from '../Texts'
import { useHovered }          from '../../hooks/useHovered'

const blazonRender = {
  square: Square,
  triangle: Triangle,
  round: Round,
}

export const getRankIcon = (rank) => {
  switch (rank) {
    case 1:
      return First
    case 2:
      return Second
    case 3:
      return Third
    default:
      return null
  }
}

export const Blazon = ({
  blazon,
  color,
  text,
  size,
  name,
  onPress,
  ...props
}) => {
  const DEFAULT_BLAZON = 'square'
  const DEFAULT_COLOR = '#D9856C'
  const DEFAULT_NAME = ' '
  const [hovered, hoverProps] = useHovered()
  const [isActive, isActiveProps] = useIsActive()
  const Icon = blazon ? blazonRender[blazon] : blazonRender[DEFAULT_BLAZON]
  const disabled = !onPress
  return (
    <View {...props}>
      <WrapperTouchableBlazon
        {...{ onPress, disabled }}
        {...hoverProps}
        {...isActiveProps}
      >
        <WrapperBlazonWithName>
          <WrapperBlazon>
            <Icon width={size} height={size} {...{ color: color ? color : DEFAULT_COLOR }} />
            {text && <BlazonText {...{ size }}>{text}</BlazonText>}
          </WrapperBlazon>
          {name && (
            <BlazonCartridge {...{ disabled, isActive, hovered }}>
              {name || DEFAULT_NAME}
            </BlazonCartridge>
          )}
        </WrapperBlazonWithName>
      </WrapperTouchableBlazon>
    </View>
  )
}

const WrapperBlazonWithName = styled.View({
  alignItems: 'center'
})

export const LeaderboardBlazon = ({
  rank,
  blazon,
  color,
  text,
  size,
  name,
  ...props
}) => {
  const RankIcon = getRankIcon(rank)
  return (
    <WrapperBlazonWithCartridge {...props}>
      {rank && (
        <WrapperRankIcon>
          <RankIcon height={size - 10} width={size - 10}/>
        </WrapperRankIcon>
      )}
      <Blazon {...{ blazon, color, text, size, name }} />
    </WrapperBlazonWithCartridge>
  )
}

const WrapperBlazon = styled.View({
  justifyContent: 'center',
  alignItems: 'center',
})

const WrapperTouchableBlazon = styled.TouchableWithoutFeedback({})

const BlazonText = styled(BoldText)(
  {
    position: 'absolute',
    color: colors.lightText,
  },
  ({ size }) => ({
    fontSize: size / 2,
    top: size / 2,
    left: size / 2,
    transform: [{translateX: '-50%'}, {translateY: '-50%'}]
  })
)

const BlazonCartridge = styled(Cartridge)(
  {
    marginTop: -10,
    paddingHorizontal: 20,
  },
  ({ disabled, isActive, hovered }) =>
    !disabled && {
      boxShadow: isActive
        ? 'inset -1px -1px 3px rgba(21, 19, 19, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)'
        : hovered
          ? 'rgba(94, 104, 121, 0.945) 1px 0px 4px'
          : 'rgba(94, 104, 121, 0.945) 1px 0px 2px',
    }
)

const WrapperBlazonWithCartridge = styled.View({
  display: 'flex',
  alignItems: 'center'
})

export const WrapperRankIcon = styled.View({
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: -8,
  zIndex: 2,
  alignSelf: 'center',
})

export const FragmentCounter = ({ fragments }) => {
  const getColor = (possessedFragment) => possessedFragment ? '#4A4A4A' : '#C9CDDD'
  const getTextColor = (possessedFragment) => possessedFragment ? '#F2F2F2' : '#4A4A4A'

  return <WrapperFragments>
      <DisplayedFragment1 fill={getColor(fragments >= 1)} width={33.3} height={22.47}/>
      <FragmentText1 color={getTextColor(fragments >= 1)}>1</FragmentText1>

    <DisplayedFragment2 fill={getColor(fragments >= 2)} width={23} height={37}/>
    <FragmentText2 color={getTextColor(fragments >= 2)}>2</FragmentText2>

    <DisplayedFragment3 fill={getColor(fragments >= 3)} width={24} height={16.5}/>
    <FragmentText3 color={getTextColor(fragments >= 3)}>3</FragmentText3>

    <DisplayedFragment4 fill={getColor(fragments >= 4)} width={32} height={17.5}/>
    <FragmentText4 color={getTextColor(fragments >= 4)}>4</FragmentText4>

    <DisplayedFragment5 fill={getColor(fragments >= 5)} width={18} height={37}/>
    <FragmentText5 color={getTextColor(fragments >= 5)}>5</FragmentText5>

    <DisplayedFragment6 fill={getColor(fragments >= 6)} width={34} height={25.5}/>
    <FragmentText6 color={getTextColor(fragments >= 6)}>6</FragmentText6>

  </WrapperFragments>
}



const FragmentText1 = styled.Text((({ color }) => ({
  position: 'absolute',
  zIndex: 2,
  left: 9,
  top: 9,
  color: color
})))

const FragmentText2 = styled.Text((({ color }) => ({
  position: 'absolute',
  zIndex: 2,
  left: 7,
  top: 38,
  color: color
})))

const FragmentText3 = styled.Text((({ color }) => ({
  position: 'absolute',
  zIndex: 2,
  left: 33,
  top: 30,
  color: color
})))

const FragmentText4 = styled.Text((({ color }) => ({
  position: 'absolute',
  zIndex: 2,
  left: 30,
  top: 51,
  color: color
})))
const FragmentText5 = styled.Text((({ color }) => ({
  position: 'absolute',
  zIndex: 2,
  left: 55,
  top: 40,
  color: color
})))
const FragmentText6 = styled.Text((({ color }) => ({
  position: 'absolute',
  zIndex: 2,
  left: 47,
  top: 5,
  color: color
})))

const WrapperFragments = styled.View({
  position: 'relative',
  width: 70,
  height: 70,
})

const DisplayedFragment1 = styled(Fragment1)`
  position: absolute;
  top: 5px;
  left: 0;
  cursor: default;
`

const DisplayedFragment2 = styled(Fragment2)`
  position: absolute;
  top: 30px;
  left: 0;
  cursor: default;
  :last-child {
    stroke: ${props => props.currentColor};
  }
  :first-child {
    stroke: transparent; !important;
  }
`

const DisplayedFragment3 = styled(Fragment3)`
  position: absolute;
  top: 30px;
  left: 25px;
  cursor: default;
  :last-child {
    stroke: ${props => props.currentColor};
  }
  :first-child {
    stroke: transparent; !important;
  }
`

const DisplayedFragment4 = styled(Fragment4)`
  position: absolute;
  top: 50px;
  left: 20px;
  cursor: default;
  :last-child {
    stroke: ${props => props.currentColor};
  }
  :first-child {
    stroke: transparent; !important;
  }
`

const DisplayedFragment5 = styled(Fragment5)`
  position: absolute;
  top: 30px;
  left: 50px;
  cursor: default;
  :last-child {
    stroke: ${props => props.currentColor};
  }
  :first-child {
    stroke: transparent; !important;
  }
`

const DisplayedFragment6 = styled(Fragment6)`
  position: absolute;
  top: 0;
  left: 34px;
  :last-child {
    stroke: ${props => props.currentColor};
  }
  :first-child {
    stroke: transparent; !important;
  }
`
