import React, {useEffect, useState} from 'react'
import styled from '@emotion/native'
import {colors} from '../../configs/colors'
import {MediumText, SmallBoldText} from '../Texts'
import {FragmentsRoundIcon, StarRoundIcon, ThunderRoundIcon} from '../Icons'
import {
  calculateRemainingTime,
  clearedOffsetDate,
} from '../../../../game-server/lib/Domain/ActionPoints/ActionPoints.helpers'

export const ActionPointsCounter = ({
                                      actionsPoints,
                                      startAt,
                                      mobile,
                                      hovered,
                                      toolTip,
                                      header,
                                      ...props
                                    }) => {
  return (
    <CounterWrapper {...props} mobile={mobile} header={header}>
      <ActionPointsIcon
        height={30}
        width={30}
        color={colors.extraLightBackground}
        wrapperColor={colors.text}
      />
      <WrapperTextInfos>
        <PointsWrapper timer={!mobile} mobile={mobile}>
          <ActionPointsText>
            {actionsPoints}
          </ActionPointsText>
        </PointsWrapper>
        {!mobile && (
          <ActionPointsCountDown startAt={startAt}/>
        )}
      </WrapperTextInfos>
      {
        hovered &&
        <WrapperTooltip mobile={mobile}>
          {toolTip}
        </WrapperTooltip>
      }
    </CounterWrapper>
  )
}

const WrapperTooltip = styled(MediumText)(({mobile}) => ({
  position: 'absolute',
  right: mobile ? -205 : -220,
  top: mobile ? 150 : '50%',
  transform: [{translateY: '-50%'}],
  padding: 15,
  backgroundColor: colors.white,
  borderRadius: 18,
  borderColor: colors.dropShadow,
  borderWidth: 2,
  width: 200
}))

const ActionPointsCountDown = ({startAt}) => {
  return (
    <TimerCartridge>
      <SmallBoldText color={colors.extraLightBackground}>
        <TimerValue startAt={startAt}/>
      </SmallBoldText>
    </TimerCartridge>
  )
}

const TimerValue = ({startAt}) => {
  if (!startAt) return null
  const [now, setNow] = useState(() => clearedOffsetDate(new Date()))
  const tick = () => setNow(clearedOffsetDate(new Date()))
  const time = calculateRemainingTime(
    now.toISOString(),
    clearedOffsetDate(startAt).toISOString()
  )
  const timerValue = calculateDateDiff(time, false)

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000)
    return () => clearInterval(timerID)
  }, [])

  return <>{timerValue}</>
}

export const PointsCounter = ({points, mobile, hovered, toolTip, ...props}) => (
  <CounterWrapper {...props}>
    <PointsIcon
      wrapperColor={colors.text}
      color={colors.extraLightBackground}
      width={30}
      height={30}
    />
    <WrapperTextInfos>
      <TextWrapper>
        <Text>{points}</Text>
      </TextWrapper>
    </WrapperTextInfos>
    {
      hovered &&
      <WrapperTooltip mobile={mobile}>
        {toolTip}
      </WrapperTooltip>
    }
  </CounterWrapper>
)

export const FragmentsCounter = ({fragments, maxFragments, hovered, toolTip, mobile, ...props}) => (
  <CounterWrapper {...props}>
    <FragmentIcon
      wrapperColor={colors.text}
      color={colors.extraLightBackground}
      width={30}
      height={30}
    />
    <WrapperTextInfos>
      <TextWrapper>
        <Text>
          {fragments}/{maxFragments}
        </Text>
      </TextWrapper>
    </WrapperTextInfos>
    {
      hovered &&
      <WrapperTooltip mobile={mobile}>
        {toolTip}
      </WrapperTooltip>
    }
  </CounterWrapper>
)

const CounterWrapper = styled.View(({mobile, header}) => ({
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'center',
  marginBottom: (mobile && !header) ? 6 : 0
}))

const WrapperTextInfos = styled.View({
  flexDirection: 'column',
  marginLeft: 15,
})

const ActionPointsIcon = styled(ThunderRoundIcon)({
  position: 'absolute',
  left: 0,
  top: -5,
  zIndex: 2,
})

const PointsIcon = styled(StarRoundIcon)({
  position: 'absolute',
  left: 0,
  top: -5,
  zIndex: 2,
})

const FragmentIcon = styled(FragmentsRoundIcon)({
  position: 'absolute',
  left: 0,
  top: -5,
  zIndex: 2,
})

const PointsWrapper = styled.View(({timer, mobile}) => ({
  backgroundColor: colors.extraLightBackground,
  borderRadius: (!timer || mobile) && 5,
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
  borderWidth: 1,
  borderColor: colors.dropShadow,
  paddingLeft: 10,
  width: 50,
  paddingVertical: 3,
}))

const ActionPointsText = styled(SmallBoldText)({
  marginLeft: 5,
})

const TimerCartridge = styled.View({
  backgroundColor: colors.timerSkeleton,
  borderBottomLeftRadius: 5,
  borderBottomRightRadius: 5,
  width: 50,
  paddingVertical: 3,
  borderWidth: 1,
  borderTopWidth: 0,
  borderColor: colors.dropShadow,
})

const TextWrapper = styled.View({
  backgroundColor: colors.extraLightBackground,
  borderRadius: 5,
  borderWidth: 1,
  borderColor: colors.dropShadow,
  width: 50,
  paddingVertical: 3,
})

const Text = styled(SmallBoldText)({
  marginLeft: 10,
})

export function calculateDateDiff(time, format) {
  const diff_hours = Math.floor(time / 60 / 60)
  const diff_minutes = Math.floor(time / 60) % 60
  const diff_seconds = time % 60
  if (!format) {
    const formattedHours =
      diff_hours.toString().length === 1
        ? '0' + diff_hours.toString()
        : diff_hours
    const formattedMinutes =
      diff_minutes.toString().length === 1
        ? '0' + diff_minutes.toString()
        : diff_minutes
    const formattedSeconds =
      diff_seconds.toString().length === 1
        ? '0' + diff_seconds.toString()
        : diff_seconds
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
  } else return `${diff_hours}h ${diff_minutes}mn`
}
