import React from 'react'
import {
  TouchableHighlight,
  Text,
  ImageBackground,
  StyleProp,
  ViewStyle,
  TextStyle
} from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

type Props = {
  disabled?: boolean
  onPress: () => void
  title: string
  style?: StyleProp<ViewStyle>
  variant?: 'valid' | 'modal'
  textStyle?: StyleProp<TextStyle>
}

export default class Button extends React.Component<Props> {
  render() {
    return (
      <TouchableHighlight
        style={this.props.style}
        activeOpacity={0.6}
        onPress={this.props.onPress}
        disabled={this.props.disabled}
        underlayColor='fff'
      >
        <ImageBackground
          source={
            this.props.disabled
              ? require('../src/images/button-civitime-valid-disabled.png')
              : this.props.variant === 'modal'
              ? require('../src/images/button-modal.png')
              : require('../src/images/button-civitime-valid.png')
          }
          style={[
            styles.btnWidth,
            this.props.variant === 'modal' ? styles.btnWidthModal : {}
          ]}
        >
          <Text
            style={[
              styles.text,
              this.props.disabled ? styles.disabled : {},
              this.props.textStyle
            ]}
          >
            {this.props.title}
          </Text>
        </ImageBackground>
      </TouchableHighlight>
    )
  }
}

const styles = EStyleSheet.create({
  btnWidth: {
    '@media (min-width: 1920)': {
      width: 300,
      height: 90
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      width: 200,
      height: 60
    },
    '@media (max-width: 1366)': {
      width: 150,
      height: 45
    }
  },
  btnWidthModal: {
    '@media (min-width: 1920)': {
      width: 390
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      width: 260
    },
    '@media (max-width: 1366)': {
      width: 195
    }
  },
  text: {
    color: '$white',
    fontFamily: '$fontFamily',
    textAlign: 'center',
    margin: 'auto',
    '@media (min-width: 2560)': {
      fontSize: 51
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      fontSize: 34
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      fontSize: 25.5
    },
    '@media (max-width: 1366)': {
      fontSize: 18
    }
  },
  disabled: {
    color: '$bgColor'
  }
})
