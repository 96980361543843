import {createFunctionalError} from "../../saga-tools/Errors"
import {ActionPointsErrors} from "./ActionPoints.errors"
import {call} from "redux-saga/effects"
import differenceInDays from "date-fns/differenceInDays"
import differenceInSeconds from "date-fns/differenceInSeconds"
import parseISO from "date-fns/parseISO"
import addMinutes from "date-fns/addMinutes"
import addDays from "date-fns/addDays"
import {obtainLock} from "../../saga-tools/locks.effects"
import {getCurrentUserId, campaignGet, getNow, sendEvent} from "../../saga-tools/Data.effects"

export function consumeActionPoint(cost) {
  return call(consumeActionPointSaga, cost)
}

function* consumeActionPointSaga(cost) {
  const userId = yield getCurrentUserId()
  yield obtainLock(`users/${userId}/PA`)
  const count = yield currentActionPointsAmount()
  if (count < cost) {
    throw createFunctionalError(ActionPointsErrors.notEnoughActionPoints)
  }
  yield sendEvent('ActionPointsSet', {
    points: cost,
  })
}

export function currentActionPointsAmount() {
  return call(wrappedCurrentActionPointsAmount)
}

function* wrappedCurrentActionPointsAmount() {
  const userId = yield getCurrentUserId()
  const pointsByDay = yield campaignGet('settings/actionPointsPerDay')
  const maxPoints = yield campaignGet('settings/maxActionPoints')
  const startAt = yield campaignGet('settings/startAt')
  const actionPointState = yield campaignGet(`states/${userId}/actionPoints`)
  const now = yield getNow()
  return calculateActualActionPoints(pointsByDay, maxPoints, actionPointState, now, startAt)
}

export function calculateActualActionPoints(pointsByDay, maxPoints, {spentActionPoints, lastPASpent}, now = new Date(), startAt) {
  const daysCount = Math.max(0, differenceInDays(
    clearedOffsetDate(now),
    addDays(clearedOffsetDate(startAt), -1),
  ))
  return maxPoints ? Math.min(maxPoints, (pointsByDay * daysCount)) - spentActionPoints
    : (pointsByDay * daysCount) - spentActionPoints
}


function getTimezoneOffset() {
  return new Date().getTimezoneOffset()
}

export const calculateRemainingTime = (now = new Date(), startAt) => {
  const rawNow = clearedOffsetDate(now)
  return differenceInSeconds(
    getNextRefresh(now, startAt),
    rawNow
  )
}
export const clearedOffsetDate = (now) => {
  const offset = getTimezoneOffset()
  const date = typeof now === "string" ? parseISO(now) : now
  return addMinutes(date, -offset)
}
export const getNextRefresh = (now = new Date(), startAt) => {
  const rawNow = clearedOffsetDate(now)
  const startDate = clearedOffsetDate(startAt)
  const daysCount = differenceInDays(
    rawNow,
    addDays(startDate, -1)
  )
  return addDays(startDate, daysCount)
}

export function canConsumeActionPoint(actionPointsPerDay, maxPoints, actionPointsState, currentDate, startAt, cost) {
  const remainingPoints = calculateActualActionPoints(actionPointsPerDay, maxPoints, actionPointsState, currentDate, startAt)

  return (remainingPoints >= cost)
}
