import { Dimensions, PixelRatio } from 'react-native'
import { useEffect, useState }    from 'react'

const sizes = Dimensions.get('window')
export const MOBILE_HEADER_SIZE = 74

export const rem = Math.min(sizes.height, sizes.width) / 720
export const asRelative = n =>
  Math.round(PixelRatio.roundToNearestPixel(n * rem))

export const asGrowingRelative = n => {
    if (n >= 500){
        return Math.round(PixelRatio.roundToNearestPixel(n * rem))
    }

    if (n < 500){
        return Math.round(PixelRatio.roundToNearestPixel(n * 1.3  * rem))
    }
}

export const getSize = () => {
    const [size, setSize] = useState(0)
    useEffect(() => {
        setSize(Dimensions.get('window').width),
            [Dimensions.get('window')]
    }, [])
    return size
}
