import styled                         from '@emotion/native'
import { PictureCard }                from './Pictures'
import React, { useEffect, useState } from 'react'
import { Animated, Dimensions }       from 'react-native'
import { useTranslator, T }              from '../translations/translate'

export const MOBILE_BREAKPOINT = 760
const screenWidth = Math.round(Dimensions.get("window").width)
const screenHeight = Math.round(Dimensions.get("window").height)

export const Description = ({
  image,
  description,
  onClose,
  endedCard,
  backgroundColor,
  win,
}) => {
  const [bigHeartAnimationScale] = useState(new Animated.Value(1))
  const {t} = useTranslator()
  useEffect(() => {
    if (win) {
      Animated.sequence([
        Animated.timing(bigHeartAnimationScale, {
          toValue: 2,
          duration: 400,
          useNativeDriver: true,
        }),
        Animated.timing(bigHeartAnimationScale, {
          toValue: 0.7,
          duration: 600,
          useNativeDriver: true,
        }),
        Animated.timing(bigHeartAnimationScale, {
          toValue: 1.3,
          duration: 400,
          useNativeDriver: true,
        }),
        Animated.timing(bigHeartAnimationScale, {
          toValue: 0.9,
          duration: 400,
          useNativeDriver: true,
        }),
        Animated.timing(bigHeartAnimationScale, {
          toValue: 1,
          duration: 400,
          useNativeDriver: true,
        }),
      ]).start()
    }
  }, [win])
  return (
    <OverlayDescription
      screenWidth={screenWidth}
      onPress={onClose}
      endedCard={endedCard}
      mobile={screenWidth < MOBILE_BREAKPOINT || endedCard}
      disabled={screenWidth >= MOBILE_BREAKPOINT || endedCard}
    >
      <DescriptionWrapper onPress={onClose}>
        <DescriptionCard
          mobile={screenWidth < MOBILE_BREAKPOINT}
          backgroundColor={backgroundColor}
        >
          {image && <PictureDescriptionCard source={image} />}
          <DescriptionScrollView>
            <DescriptionContent>
              <T path={description}/>
            </DescriptionContent>
            {screenWidth < MOBILE_BREAKPOINT || endedCard ? (
              <WrapperButton>
                <WrapperConfirmButton onPress={onClose}>
                  <TextConfirmButton>
                    {t('app.okButton')}
                  </TextConfirmButton>
                </WrapperConfirmButton>
              </WrapperButton>
            ) : null}
          </DescriptionScrollView>
          {win ? (
            <WrapperHeartAnimation>
              <HeartAnimation
                source={require("../images/heart-big.png")}
                bigHeartAnimationScale={bigHeartAnimationScale}
              />
            </WrapperHeartAnimation>
          ) : null}
        </DescriptionCard>
      </DescriptionWrapper>
    </OverlayDescription>
  )
}

const WrapperHeartAnimation = styled.View({
  marginBottom: 50,
  alignItems: 'center',
})

const HeartAnimation = styled(Animated.Image)(({bigHeartAnimationScale}) => ({
  width: 60,
  height: 60,
  resizeMode: "contain",
  transform: [{scale: bigHeartAnimationScale}]
}))

const TextConfirmButton = styled.Text({
  color: "white",
})

const WrapperButton = styled.View({
  alignSelf: "center",
})

const WrapperConfirmButton = styled.TouchableOpacity({
  marginBottom: 10,
  borderRadius: 50,
  backgroundColor: "#F38463",
  paddingVertical: 5,
  paddingHorizontal: 15,
})

const OverlayDescription = styled.View(
  {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    maxWidth: "25%",
  },
  ({ mobile, endedCard, screenWidth }) => ({
    alignItems: endedCard ? 'center' : 'unset',
    width: endedCard ? screenWidth : 'unset',
    maxWidth: mobile ? endedCard ? "unset" : "80%" : '25%',
    margin: mobile ? "auto" : "unset",
    top: mobile ? 0 : 0,
    right: mobile ? 0 : "unset",
    left: mobile ? 0 : "70%",
    bottom: mobile ? 0 : "unset",
    zIndex: 6,
  })
)

const DescriptionWrapper = styled.View({
  borderRadius: 5,
  zIndex: 3,
})

const DescriptionCard = styled.View(
  {
    borderRadius: 5,
    shadowColor: "black",
    shadowOpacity: 0.5,
    paddingTop: 45,
    alignItems: "center",
    display: 'inline-block'
  },
  ({ mobile, backgroundColor }) => ({
    marginBottom: mobile ? 30 : "auto",
    backgroundColor: backgroundColor ? backgroundColor : "#722F4F",
  })
)

const DescriptionScrollView = styled.ScrollView({
  maxHeight: screenHeight * 0.45,
  alignSelf: "normal",
})

const DescriptionContent = styled.Text({
  color: "white",
  textAlign: "center",
  paddingTop: 22,
  paddingRight: 20,
  paddingBottom: 37,
  paddingLeft: 20,
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: 18,
})

const PictureDescriptionCard = styled(PictureCard)({
  backgroundColor: "white",
  position: "absolute",
  top: -42.5,
  zIndex: 2,
  left: '50%',
  transform: [{translateX: '-50%'}]
})
