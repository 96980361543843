import React, { useState } from 'react'
import { DuelQuizGame }    from './src/Game'

export const DuelQuiz = ({ data, onClose, playerProfile, opponentProfile, opponentResult, contentTranslations, lang }) => {
  const [timeStampStart] = useState(new Date())
  return <DuelQuizGame data={data}
                       onClose={onClose}
                       playerProfile={playerProfile}
                       opponentResult={opponentResult}
                       contentTranslations={contentTranslations}
                       lang={lang}
                       timeStampStart={timeStampStart}
                       opponentProfile={opponentProfile}/>
}


