import React from "react"
import { TouchableWithoutFeedback } from "react-native"
import styled from "@emotion/native"
import { colors } from "../../configs/colors"
import { brand as isMobileApp } from "expo-device"

export const Checkbox = ({ children, value, onChange, rounded, ...props }) => {
  return (
    <TouchableWithoutFeedback
      {...props}
      onPress={() => onChange && onChange(!value)}
    >
      <Container {...props}>
        <Check rounded={rounded} active={value} />
        {children}
      </Container>
    </TouchableWithoutFeedback>
  )
}

const Check = styled.View(
  {
    width: 16,
    height: 16,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: colors.secondary,
    marginRight: 15,
  },
  ({ rounded, active }) => ({
    borderRadius: rounded ? 16 / 2 : 0,
    backgroundColor: active ? colors.secondary : colors.lightBackground,
    ...(active
      ? {
          ...(isMobileApp
            ? {
                borderColor: colors.mobileButtonBorder,
              }
            : {
                borderWidth: 0,
                boxShadow:
                  "inset -1px -1px 3px rgba(255, 255, 255, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)",
              }),
        }
      : {
          shadowColor: colors.lightShadow,
          shadowOffset: {
            width: 0,
            height: 3,
          },
          shadowOpacity: 0.27,
          shadowRadius: 4.65,

          elevation: 6,
        }),
  })
)

const Container = styled.View({
  flex: 1,
  alignSelf: "stretch",
  flexDirection: "row",
  alignItems: "center",
})
