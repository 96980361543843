import React from "react";
import styled from "@emotion/native";
import { colors } from "../../configs/colors";

export const Title1 = ({ children, color, ...props }) => <H1Styled {...{ color }} {...props}>{children}</H1Styled>
export const Title2 = ({ children, color, ...props }) => <H2Styled {...{ color }} {...props}>{children}</H2Styled>
export const MediumText = ({ children, color, ...props }) => <MediumTextStyle {...{ color }} {...props}>{children}</MediumTextStyle>
export const BoldText = ({ children, color, ...props }) => <BoldTextStyle {...{ color }} {...props}>{children}</BoldTextStyle>
export const SmallText = ({ children, color, ...props }) => <SmallTextStyle {...{ color }} {...props}>{children}</SmallTextStyle>
export const SmallBoldText = ({ children, color, ...props }) => <SmallBoldTextStyle {...{ color }} {...props}>{children}</SmallBoldTextStyle>
export const ButtonText = ({ children, color, ...props }) => <ButtonTextStyle {...{ color }} {...props}>{children}</ButtonTextStyle>
export const XSItalicSemiBold = ({ children, color, ...props }) => <ItalicSemiBoldStyle {...{ color }} {...props}>{children}</ItalicSemiBoldStyle>
export const XSSemiBold = ({ children, color, ...props }) => <XSSemiBoldStyle {...{ color }} {...props}>{children}</XSSemiBoldStyle>
export const XSBold = ({ children, color, ...props }) => <XSBoldStyle {...{ color }} {...props}>{children}</XSBoldStyle>
export const Cartridge = ({ children, color, backgroundColor, ...props }) => (
  <CartridgeWrapper backgroundColor={backgroundColor} {...props}>
    <CartridgeText {...{ color }}>{children}</CartridgeText>
  </CartridgeWrapper>
);

export const BaseText = styled.Text({
  fontFamily: "Raleway_500Medium",
  fontWeight: "500",
  textAlign: "center",
  fontStyle: "normal",
});

const H1Styled = styled(BaseText)(({ color }) => ({
  fontFamily: "Raleway_700Bold",
  fontWeight: "bold",
  fontSize: 18,
  lineHeight: 21,
  color: color ? color : colors.text,
}));

const H2Styled = styled(BaseText)(({ color }) => ({
  fontFamily: "Raleway_700Bold",
  fontWeight: "bold",
  fontSize: 15,
  lineHeight: 18,
  color: color ? color : colors.text,
}));

const MediumTextStyle = styled(BaseText)(({ color }) => ({
  fontFamily: "Raleway_500Medium",
  fontWeight: "500",
  fontSize: 15,
  lineHeight: 18,
  color: color ? color : colors.text,
}));

const BoldTextStyle = styled(BaseText)(({ color }) => ({
  fontFamily: "Raleway_700Bold",
  fontWeight: "bold",
  fontSize: 12,
  lineHeight: 14,
  color: color ? color : colors.text,
}));

const SmallBoldTextStyle = styled(BaseText)(({ color }) => ({
  fontFamily: "Raleway_700Bold",
  fontWeight: "bold",
  fontSize: 10,
  lineHeight: 12,
  color: color ? color : colors.text,
}));

const SmallTextStyle = styled(BaseText)(({ color }) => ({
  fontFamily: "Raleway_500Medium",
  fontWeight: "500",
  fontSize: 12,
  lineHeight: 14,
  color: color ? color : colors.text,
}));

const ButtonTextStyle = styled(BaseText)(({ color }) => ({
  fontFamily: "Raleway_700Bold",
  fontWeight: "bold",
  fontSize: 16,
  lineHeight: 19,
  color: color ? color : colors.secondary,
  textTransform: "uppercase",
}));

const CartridgeWrapper = styled.View(({ backgroundColor }) => ({
  backgroundColor: backgroundColor ? backgroundColor : colors.text,
  borderRadius: 5,
  paddingVertical: 3,
  paddingHorizontal: 30,
}));

const CartridgeText = styled(BaseText)(({ color }) => ({
  fontFamily: "Raleway_700Bold",
  fontWeight: "bold",
  fontSize: 12,
  lineHeight: 14,
  color: color ? color : colors.lightText,
  textTransform: "uppercase",
}));

const XSSemiBoldStyle = styled(BaseText)(({ color }) => ({
  fontFamily: "Raleway_600SemiBold",
  fontWeight: "600",
  fontSize: 9,
  lineHeight: 11,
  color: color ? color : colors.text,
  textTransform: "uppercase",
}));

const XSBoldStyle = styled(BaseText)(({ color }) => ({
  fontFamily: "Raleway_700Bold",
  fontWeight: "bold",
  fontSize: 9,
  lineHeight: 11,
  color: color ? color : colors.text,
  textTransform: "uppercase",
}));

const ItalicSemiBoldStyle = styled(BaseText)(({ color }) => ({
  fontFamily: "Raleway_600SemiBold_Italic",
  fontStyle: "italic",
  fontSize: 9,
  lineHeight: 11,
  color: color ? color : colors.text,
  textTransform: "uppercase",
}));
