import React from 'react'
import {Text, View, Platform, Dimensions, ScrollView} from 'react-native'
import {MasterMindProps, Answer, Data, EndMessage} from './types'
import Header from './components/header'
import Content from './components/content'
import Footer from './components/footer'
// @ts-ignore
import {T} from './components/useLinkInText'
// @ts-ignore
import Modal from 'modal-react-native-web'
import EStyleSheet from 'react-native-extended-stylesheet'
import ModalPresentation from './components/modalpresentation'
import './styles.css'

EStyleSheet.build({
  $white: '#FFF',
  $bgColor: '#EDB8AC',
  $textColor: '#493065',
  $fontFamily: 'Raleway'
})

if (Platform.OS === 'web') {
  Modal.setAppElement('#root')
}

type State = {
  showModalPresentation: boolean
  reRender: boolean
}

export class MasterMind extends React.Component<MasterMindProps, State> {
  state = {
    showModalPresentation: true,
    reRender: false
  }

  componentDidMount() {
    Dimensions.addEventListener('change', () => {
      EStyleSheet.clearCache()
      EStyleSheet.build() // force style re-calculation
      this.setState({...this.state, reRender: true}, () => {
        this.setState({...this.state, reRender: false})
      })
    })
  }

  _closeModalPresentation = () => {
    this.setState({showModalPresentation: false})
  }

  render() {
    const {reRender} = this.state
    return (
      <View style={styles.window}>
        <ScrollView style={styles.scrollView}>
          <View style={styles.page}>
            <Header
              onQuit={this.props.onQuit}
              translations={this.props.translations}
            />
            <View style={styles.questionContainer}>
              <Text style={styles.question}>
                <T
                  text={
                    this.props.contentTranslations
                      ? this.props.contentTranslations[this.props.data.question]
                      : this.props.data.question
                  }
                />
              </Text>
            </View>
            <Content
              reRender={reRender}
              answers={this.props.data.answers}
              onEnd={this.props.onEnd}
              level={this.props.level}
              translations={this.props.translations}
              contentTranslations={this.props.contentTranslations}
            />
            <Footer translations={this.props.translations}/>
          </View>
          <ModalPresentation
            visible={this.state.showModalPresentation}
            closeModal={this._closeModalPresentation}
            intro={this.props.data.intro}
            translations={this.props.translations}
            contentTranslations={this.props.contentTranslations}
          />
        </ScrollView>
      </View>
    )
  }
}

export {MasterMindProps, Answer, Data, EndMessage}

const styles = EStyleSheet.create({
  window: {
    backgroundColor: '$bgColor',
    minWidth: '100%',
    minHeight: '100%',
    flex: 1,
    margin: 0,
    padding: 0,
    overscrollBehavior: 'none',
    overflowY: 'hidden',
    fontFamily: 'Raleway, sans-serif'
  },
  scrollView: {
    width: '100%'
  },
  page: {
    marginHorizontal: 'auto',
    '@media (min-width: 2560)': {
      width: 2436
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      width: 1624
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      width: 1218
    },
    '@media (min-width: 1023) and (max-width: 1366)': {
      width: 700
    },
    '@media (max-width: 1023)': {
      width: '100%'
    }
  },
  questionContainer: {
    width: 'auto',
    display: 'inline-block',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: 15,
    '@media (min-width: 2560)': {
      height: 300
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      height: 200
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      height: 100
    },
    '@media (min-width: 767) and (max-width: 1366)': {
      height: 100
    },
    '@media (max-width: 767)': {
      height: 40,
      width: '90%'
    }
  },
  question: {
    userSelect: 'none',
    color: '$textColor',
    fontFamily: '$fontFamily',
    textAlign: 'center',
    '@media (min-width: 2560)': {
      fontSize: 54,
      lineHeight: 60
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      fontSize: 34,
      lineHeight: 40
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      fontSize: 27,
      lineHeight: 30
    },
    '@media (min-width: 767) and (max-width: 1366)': {
      fontSize: 21,
      lineHeight: 24
    },
    '@media (max-width: 767)': {
      fontSize: 18,
      lineHeight: 18
    }
  }
})
