import React, {useState} from 'react'
import styled from "@emotion/native"
import {colors} from "@civitime/library/storybook/configs/colors"
import {CloseButtonComponent} from "./CloseButton"
import {QuestionComponents} from "./QuestionsComponents"
import {ResultsScreen} from "./ResultsScreen"

export const QuestionsScreen = ({
                                  screenDimensions, mobile, onClose, data, sendResult, result, playerProfile,
                                  updateQuestionsTimeStamps,
                                  opponentProfile, opponentResult, contentTranslations, timeStampStart,
                                  questionsTimeStamps
                                }) => {
  const [showResults, setShowResults] = useState(false)
  return <WrapperQuestionsScreen screenDimensions={screenDimensions}>
    <BackgroundQuestionsScreen screenDimensions={screenDimensions}
                               source={require('../Assets/backgroud-game.png')}
                               alt='background questions'/>
    <GameLogo screenDimensions={screenDimensions}
              source={require('../Assets/logo.png')}
              alt='logo duel quiz'
              mobile={mobile}/>
    {
      !showResults &&
      <CloseButtonComponent screenDimensions={screenDimensions} mobile={mobile} onClose={onClose} result={result}
                            data={data}/>
    }
    {
      showResults ?
        <ResultsScreen screenDimensions={screenDimensions} mobile={mobile} result={result}
                       playerProfile={playerProfile} opponentResult={opponentResult} timeStampStart={timeStampStart}
                       opponentProfile={opponentProfile} onClose={onClose} data={data}
                       questionsTimeStamps={questionsTimeStamps}/>
        :
        <QuestionComponents screenDimensions={screenDimensions} mobile={mobile} data={data}
                            contentTranslations={contentTranslations}
                            updateQuestionsTimeStamps={updateQuestionsTimeStamps}
                            sendResult={sendResult} result={result} showResults={() => setShowResults(true)}
        />
    }
  </WrapperQuestionsScreen>
}

const WrapperQuestionsScreen = styled.View(({screenDimensions}) => ({
  width: screenDimensions.width,
  height: screenDimensions.height,
  backgroundColor: colors.whisper
}))

const BackgroundQuestionsScreen = styled.Image(({screenDimensions}) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: screenDimensions.width,
  height: screenDimensions.height,
}))

const GameLogo = styled.Image(({screenDimensions, mobile}) => ({
  position: 'absolute',
  left: (screenDimensions.width - (mobile ? (screenDimensions.width / 2) : (screenDimensions.width / 5))) / 2,
  top: 25,
  width: mobile ? (screenDimensions.width / 2) : (screenDimensions.width / 5),
  height: (mobile ? (screenDimensions.width / 2) : (screenDimensions.width / 5)) * 0.18,
}))


