import React from 'react'
import { Text, View, Animated, Easing } from 'react-native'
import { DragAndDropContext } from '@civitime/react-native-easy-dnd/src/dragAndDropContext'
import EStyleSheet from 'react-native-extended-stylesheet'
import { AnswerExtended } from '../types'
import Item from './item'
// @ts-ignore
import LottieView from 'react-native-web-lottie'
import DropZone from './dropzone'

type Props = {
  customId: string
  currentAnswer?: AnswerExtended
  correctAnswer: AnswerExtended
  dragAndDropContext: DragAndDropContext
  onMoveToDoneAnswer: (answer: AnswerExtended) => void
  onAnimationEnd: () => void
  onDragStart: () => void
  trad: any
  updateInfosTimeStamps: (e: {}) => void
}

type State = {
  drag: boolean
  resultAnimationProgress: Animated.Value
}

export default class DoneCell extends React.Component<Props, State> {
  state = { drag: false, resultAnimationProgress: new Animated.Value(0) }

  _onDragStart = () => {
    this.props.onDragStart()
    this.setState({ drag: true })
  }

  _onDragEnd = () => {
    this.setState({ drag: false })
  }

  validate = () => {
    Animated.timing(this.state.resultAnimationProgress, {
      toValue: 1,
      duration: 500,
      easing: Easing.linear,
      useNativeDriver: true
    }).start(() => {
      this.props.onAnimationEnd()
    })
  }

  resetAnimation = () => {
    if (
      this.props.currentAnswer?.position !== this.props.correctAnswer.position
    ) {
      this.state.resultAnimationProgress.setValue(0)
    }
  }

  render() {
    return (
      <View
        style={[
          styles.doneCell,
          this.props.currentAnswer && this.state.drag ? styles.itemDrag : {}
        ]}
      >
        <View style={styles.line}>
          <Text style={styles.donePositionText}>
            {this.props.correctAnswer.position + 1}
          </Text>
          {this.props.currentAnswer === undefined ? (
            <DropZone
              customId={this.props.customId}
              dragAndDropContext={this.props.dragAndDropContext}
              onDrop={this.props.onMoveToDoneAnswer}
              image={require('../src/images/item-holder-civitime.png')}
              activeImage={require('../src/images/item-holder-filled-civitime.png')}
            />
          ) : (
            <Item
              customId={this.props.customId}
              answer={this.props.currentAnswer}
              dragAndDropContext={this.props.dragAndDropContext}
              onDragStart={this._onDragStart}
              onDragEnd={this._onDragEnd}
              trad={this.props.trad}
              updateInfosTimeStamps={(timeStamps) => this.props.updateInfosTimeStamps(timeStamps)}
            />
          )}
          <LottieView
            source={
              this.props.currentAnswer?.position ===
              this.props.correctAnswer.position
                ? require('../src/animations/good-result-animation.json')
                : require('../src/animations/false-result-animation.json')
            }
            style={styles.doneLottie}
            autoPlay={false}
            loop={false}
            progress={this.state.resultAnimationProgress}
          />
        </View>
      </View>
    )
  }
}

const styles = EStyleSheet.create({
  itemDrag: {
    zIndex: 3500
  },
  doneCell: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  line: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  donePositionText: {
    userSelect: 'none',
    fontFamily: '$fontFamily',
    color: '$textColor',
    zIndex: 2500,
    '@media (min-width: 2560)': {
      fontSize: 51,
      marginRight: 18
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      fontSize: 34,
      marginRight: 12
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      fontSize: 29,
      marginRight: 9
    },
    '@media (max-width: 1366)': {
      fontSize: 26,
      marginRight: 5
    }
  },
  doneLottie: {
    alignSelf: 'center',
    '@media (min-width: 2560)': {
      width: 60,
      height: 54
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      width: 40,
      height: 36
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      width: 30,
      height: 27
    },
    '@media (max-width: 1366)': {
      width: 20,
      height: 18
    }
  }
})
