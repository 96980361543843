import React, { useEffect, useState }   from 'react'
import { Game }               from './src/Game'
import * as ScreenOrientation from 'expo-screen-orientation'
import { Platform }           from 'react-native'

async function changeScreenOrientation () {
  await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT)
}

export const App = ({
  level,
  data,
  contentTranslations,
  onClose,
  lang
}) => {
  const [timeStampStart] = useState(new Date())
  useEffect(() => {
    if (Platform.OS === 'android' || Platform.OS === 'ios') {
      changeScreenOrientation()
    }
  }, [])
  return (
    <Game onClose={onClose}
          timeStampStart={timeStampStart}
          level={level}
          data={data}
          contentTranslations={contentTranslations}
          lang={lang}/>
  )
}


