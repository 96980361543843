import {useEffect, useState} from "react"
import {Dimensions} from "react-native"


export const useScreenDimensions = (ref) => {
  const [dimensions, setDimensions] = useState({})
  
  const getScreenDimensions = () => {
    setDimensions({
      width: Dimensions.get('window').width,
      height: Dimensions.get('window').height
    })
  }
  
  useEffect(() => {
    if(!ref?.current) return
    setDimensions({
      width: Dimensions.get('window').width,
      height: Dimensions.get('window').height
    })
    Dimensions.addEventListener( 'change', getScreenDimensions)
    return () => Dimensions.removeEventListener('change', getScreenDimensions)
  }, [])
  
  return {
    width: dimensions?.width,
    height: dimensions?.height,
  }
}