import React from 'react'
import {
  Text,
  ImageBackground,
  Image,
  Animated,
  TouchableHighlight,
  View,
  Platform
} from 'react-native'
import {DragAndDropContext} from '@civitime/react-native-easy-dnd/src/dragAndDropContext'
import EStyleSheet from 'react-native-extended-stylesheet'
import ModalAnswerExplanation from './modalanswerexplanation'
// @ts-ignore
import {T} from '../components/useLinkInText'

type Props = {
  customId: string
  answer: any
  dragAndDropContext: DragAndDropContext
  onDragStart: () => void
  onDragEnd: () => void
  trad: any
  updateInfosTimeStamps: (e: {}) => void
}

type State = {
  showInfoModal: boolean
  drag: boolean
  isHoveringInfoButton: boolean
  timeStamps: {}
}

export default class Item extends React.Component<Props, State> {
  private _isMounted = false
  state = {
    showInfoModal: false,
    drag: false,
    isHoveringInfoButton: false,
    timeStamps: {}
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  _openInfoModal = () => {
    this.setState({
      ...this.state, showInfoModal: true,
      timeStamps: {
        timeStampStart: new Date()
      }
    })
  }

  _closeInfoModal = () => {
    this.props.updateInfosTimeStamps({
      ...this.state.timeStamps,
      id: this.props.customId,
      answer: this.props.answer,
      label: this.props.trad ? this.props.trad[this.props.answer.label] : this.props.answer.label
    })
    this.setState({ ...this.state, showInfoModal: false })
  }

  _onDragStart = () => {
    this.props.onDragStart()
    this.setState({...this.state, drag: true})
  }

  _onDragEnd = () => {
    if (this._isMounted) {
      this.props.onDragEnd()
      this.setState({...this.state, drag: false})
    }
  }

  render() {
    const {Draggable} = this.props.dragAndDropContext
    return (
      <Draggable
        customId={`draggable-${this.props.customId}`}
        payload={this.props.answer}
        onDragStart={this._onDragStart}
        onDragEnd={this._onDragEnd}
      >
        {({viewProps}) => (
          <Animated.View
            {...viewProps}
            {...Platform.select({
              web: {
                onTouchStart: (event) => event.preventDefault(),
                onTouchMove: (event) => event.preventDefault()
              }
            })}
            style={[
              viewProps.style,
              styles.item,
              this.state.drag ? styles.itemDrag : {}
            ]}
          >
            <ImageBackground
              resizeMode='contain'
              style={styles.imageBackground}
              source={require('../src/images/item-civitime.png')}
            >
              <View style={styles.itemContent}>
                <Text style={styles.text}>
                  <T
                    text={
                      this.props.trad
                        ? this.props.trad[this.props.answer.label]
                        : this.props.answer.label
                    }
                  />
                </Text>
                <TouchableHighlight
                  style={styles.infoBlock}
                  onPress={this._openInfoModal}
                  {...Platform.select({
                    web: {
                      onMouseEnter: () => {
                        this.setState({
                          isHoveringInfoButton: true
                        })
                      },
                      onMouseLeave: () => {
                        this.setState({
                          ...this.state,
                          isHoveringInfoButton: false
                        })
                      }
                    }
                  })}
                >
                  <Image
                    style={styles.infoImage}
                    source={
                      this.state.isHoveringInfoButton
                        ? require('../src/images/button-info-hover.png')
                        : require('../src/images/button-info.png')
                    }
                  />
                </TouchableHighlight>
              </View>
              <ModalAnswerExplanation
                text={
                  this.props.trad
                    ? this.props.trad[this.props.answer.explanation]
                    : this.props.answer.explanation
                }
                closeModal={this._closeInfoModal}
                visible={this.state.showInfoModal}
              />
            </ImageBackground>
          </Animated.View>
        )}
      </Draggable>
    )
  }
}

const styles = EStyleSheet.create({
  itemDrag: {
    opacity: 0.8
  },
  item: {
    touchAction: 'none',
    margin: 'auto',
    userSelect: 'none',
    cursor: 'grab',
    zIndex: 3000,
    '@media (min-width: 2560)': {
      width: 636,
      height: 144
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      width: 424,
      height: 96
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      width: 318,
      height: 72
    },
    '@media (max-width: 1366)': {
      width: 212,
      height: 48
    }
  },
  imageBackground: {
    flex: 1,
    width: undefined,
    height: undefined,
    cursor: 'pointer',
  },
  itemContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 10,
    marginRight: 10,
  },
  text: {
    fontFamily: '$fontFamily',
    color: '$white',
    zIndex: 3000,
    wordBreak: 'break-word',
    paddingRight: 5,
    '@media (min-width: 2560)': {
      fontSize: 34,
      lineHeight: 38
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      fontSize: 22,
      lineHeight: 22
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      fontSize: 16,
      lineHeight: 20
    },
    '@media (min-width: 1023) and (max-width: 1366)': {
      fontSize: 11,
      lineHeight: 12
    },
    '@media (max-width: 1023)': {
      fontSize: 11,
      lineHeight: 12
    }
  },
  infoBlock: {
    zIndex: 3000,
    justifySelf: 'end'
  },
  infoImage: {
    height: 30,
    width: 30
  }
})
