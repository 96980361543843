export function createFunctionalError (type, payload = {}) {
  return {
    type,
    isFunctionalError: true,
    payload: {
      ...payload
    }
  }
}

export function isFunctionalError (error) {
  return !!error?.isFunctionalError
}
