import React from 'react'
import { Text, View } from 'react-native'
// @ts-ignore
import Modal from 'modal-react-native-web'
import EStyleSheet from 'react-native-extended-stylesheet'
import { Video } from 'expo-av'
import CloseButton from './closebutton'
import * as Device from 'expo-device'
// @ts-ignore
import { T } from './useLinkInText'

type ModalHelpProps = {
  closeModal: () => void
  translations: any
}

type ModalHelpState = {
  isHoveringCloseButtonModal: boolean
  isMobile: boolean
}

export default class ModalHelp extends React.Component<
  ModalHelpProps,
  ModalHelpState
> {
  constructor(props: ModalHelpProps) {
    super(props)
    this.state = { isHoveringCloseButtonModal: false, isMobile: false }
    Device.getDeviceTypeAsync().then((device: Device.DeviceType) => {
      this.setState({
        ...this.state,
        isMobile: device === Device.DeviceType.PHONE
      })
    })
  }

  render() {
    return (
      <Modal closeModal={this.props.closeModal} onlyCloseWithButton>
        <View style={styles.container}>
          <View style={styles.header}>
            <Text style={styles.title}>
              <T text={this.props.translations.app.modalHelp.title} />
            </Text>
            <View style={styles.closeGroup}>
              <CloseButton
                style={{ alignSelf: 'center' }}
                close={this.props.closeModal}
              />
              <Text style={styles.textQuit}>
                <T text={this.props.translations.app.modalHelp.close} />
              </Text>
            </View>
          </View>
          <Video
            source={
              this.state.isMobile
                ? require('../src/videos/tuto-mobile-mastermind.mp4')
                : require('../src/videos/tuto-desk-mastermind.mp4')
            }
            rate={1.0}
            isMuted
            resizeMode='contain'
            shouldPlay={false}
            isLooping={false}
            style={styles.video}
            useNativeControls
          />
        </View>
      </Modal>
    )
  }
}

const styles = EStyleSheet.create({
  container: {
    backgroundColor: 'white',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  header: {
    height: '10%',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center'
  },
  title: {
    flex: 1,
    textAlign: 'center',
    fontFamily: '$fontFamily',
    color: '$textColor',
    fontSize: 18
  },
  closeGroup: {
    position: 'absolute',
    right: 5
  },
  textQuit: {
    textAlign: 'center',
    fontFamily: '$fontFamily',
    color: '$textColor',
    fontSize: 18
  },
  video: {
    width: '100%',
    height: '88%'
  }
})
