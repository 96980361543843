import React, { useRef, useState }   from 'react'
import styled                        from '@emotion/native'
import { useScreenDimensions }       from '@civitime/library/storybook/hooks/useScreenDimensions'
import { StartScreen }               from './Components/StartScreen'
import { QuestionsScreen }           from './Components/QuestionsScreen'
import { TranslationModuleProvider } from './translations/translate'

export const DuelQuizGame = ({
  onClose,
  data,
  playerProfile,
  opponentProfile,
  opponentResult,
  contentTranslations,
  lang,
  timeStampStart
}) => {

  const root = useRef(null)
  const { width, height, mobile } = useScreenDimensions(root)
  const [isInitialized, setIsInitialized] = useState(false)
  const [result, setResult] = useState({})
  const [questionsTimeStamps, setQuestionsTimeStamps] = useState({})

  return <MainWrapper width={width} height={height} ref={root}>
    <TranslationModuleProvider lang={lang} moduleContent={contentTranslations}>
      {
        isInitialized ?
          <QuestionsScreen
            screenDimensions={{ width: width, height: height }}
            onClose={onClose}
            data={data}
            mobile={mobile}
            result={result}
            timeStampStart={timeStampStart}
            playerProfile={playerProfile}
            opponentProfile={opponentProfile}
            opponentResult={opponentResult}
            contentTranslations={contentTranslations}
            updateQuestionsTimeStamps={(e) => setQuestionsTimeStamps(e)}
            questionsTimeStamps={questionsTimeStamps}
            sendResult={(id, newResult) => setResult((result) => ({
              ...result,
              [id]: newResult
            }))}/> :
          <StartScreen
            screenDimensions={{ width: width, height: height }}
            initialized={() => setIsInitialized(true)}
            mobile={mobile}
          />
      }
    </TranslationModuleProvider>
  </MainWrapper>
}

const MainWrapper = styled.View(({ width, height }) => ({
  width: width,
  height: height,
}))
