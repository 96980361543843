import React from "react"
import { View, TouchableWithoutFeedback } from "react-native"
import styled from "@emotion/native"
import { useHovered } from "../../hooks/useHovered"
import { useIsActive } from "../../hooks/useIsActive"
import { colors } from "../../configs/colors"
import { BoldText } from "../Texts"
import { getRankIcon, WrapperRankIcon } from "../Blazon"
import WomanSvg from "../../../assets/woman.svg"
import ManSvg from "../../../assets/man.svg"

const avatarType = {
  man: ManSvg,
  woman: WomanSvg,
}

const getAvatar = (type) => {
  return type ? avatarType[type] : avatarType["man"]
}

export const AvatarComponent = ({
  playerName,
  circleAvatarConfig,
  cartridgeConfig,
  onPress,
  rank,
  isActiveOverride,
  avatar,
  ...props
}) => {
  const [hovered, hoverProps] = useHovered()
  const [isActive, isActiveProps] = useIsActive()
  const disabled = !onPress
  const RankIcon = getRankIcon(rank)
  const Avatar = getAvatar(avatar?.type)
  const active = isActiveOverride ? isActiveOverride : isActive
  return (
    <View {...props}>
      {RankIcon && (
        <WrapperRankIcon>
          <RankIcon
            height={circleAvatarConfig.size - 5}
            width={circleAvatarConfig.size - 5}
          />
        </WrapperRankIcon>
      )}
      <WrapperLinkAvatarBasic
        {...{ onPress, disabled }}
        {...hoverProps}
        {...isActiveProps}
      >
        <WrapperAvatarBasic>
          <AvatarBaseCircle
            {...{ circleAvatarConfig, hovered, active, disabled }}
          >
            <Avatar width={"100%"} height={"100%"} color={avatar?.color} />
          </AvatarBaseCircle>
          {playerName && (
            <CartridgeBasic {...{ cartridgeConfig, hovered, active, disabled }}>
              {playerName}
            </CartridgeBasic>
          )}
        </WrapperAvatarBasic>
      </WrapperLinkAvatarBasic>
    </View>
  )
}

const WrapperLinkAvatarBasic = styled.TouchableWithoutFeedback({})

const WrapperAvatarBasic = styled.View({
  alignItems: "center",
})

const AvatarBaseCircle = ({
  circleAvatarConfig,
  hovered,
  active,
  children,
  disabled,
}) => {
  return (
    <AvatarCircleWrapperBasic
      size={circleAvatarConfig?.size}
      height={circleAvatarConfig?.height}
      width={circleAvatarConfig?.width}
      withBorder={circleAvatarConfig?.withBorder}
      backgroundColor={circleAvatarConfig?.backgroundColor}
      {...{ hovered, isActive: active, disabled }}
    >
      {children}
    </AvatarCircleWrapperBasic>
  )
}

const AvatarCircleWrapperBasic = styled.View(
  {
    overflow: "hidden",
    borderColor: colors.dropShadow,
    zIndex: 2,
    paddingTop: 5,
  },
  ({ size, height, width, withBorder, backgroundColor, hovered, isActive, disabled }) => ({
    borderWidth: withBorder ? 1 : 0,
    backgroundColor: isActive ? colors.secondaryLight : backgroundColor,
    width: width ? width : size,
    height: height ? height : size,
    borderRadius: size / 2,
    ...(!isActive
      ? {
          boxShadow:
            !disabled && hovered
              ? "rgba(94, 104, 121, 0.945) 1px 0px 4px"
              : "rgba(94, 104, 121, 0.945) 1px 0px 2px",
        }
      : {
          boxShadow:
            "inset -1px -1px 3px rgba(255, 255, 255, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)",
        }),
  })
)

const CartridgeBasic = ({ cartridgeConfig, children, hovered, isActive }) => {
  const transformStyle = {
    transform: [
      {
        translateY: -4,
      },
    ],
  }

  return (
    <WrapperBasicCartridge
      height={cartridgeConfig.height}
      backgroundColor={cartridgeConfig.backgroundColor}
      style={[transformStyle]}
      {...{ hovered, isActive }}
    >
      <PlayerName
        color={cartridgeConfig.color ? cartridgeConfig.color : colors.lightText}
      >
        {children}
      </PlayerName>
    </WrapperBasicCartridge>
  )
}

const WrapperBasicCartridge = styled.View(
  {
    borderRadius: 5,
    paddingVertical: 5,
    paddingHorizontal: 15,
    justifyContent: "center",
    alignItems: "center",
  },
  ({ height, backgroundColor, hovered, isActive }) => ({
    backgroundColor: backgroundColor || "#4A4A4A",
    height: height,
    ...(!isActive
      ? {
          boxShadow: hovered
            ? "rgba(94, 104, 121, 0.945) 1px 0px 4px"
            : "rgba(94, 104, 121, 0.945) 1px 0px 2px",
        }
      : {
          boxShadow:
            "inset -1px -1px 3px rgba(21, 19, 19, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)",
        }),
  })
)

const PlayerName = styled(BoldText)({
  textTransform: "uppercase",
})

// Avatar Item Card
export const AvatarItemCard = ({
  children,
  activeBackground,
  cardSize,
  onPress,
  ...props
}) => {
  const [hover, hoverProps] = useHovered()
  const [isActive, isActiveProps] = useIsActive()

  return (
    <View {...props}>
      <TouchableWithoutFeedback
        {...{ onPress }}
        disabled={!onPress}
        {...hoverProps}
        {...isActiveProps}
      >
        <AvatarItemWrapper
          {...{ isActive, activeBackground, hover }}
          size={cardSize}
          customPadding={15}
        >
          {children}
        </AvatarItemWrapper>
      </TouchableWithoutFeedback>
    </View>
  )
}

const AvatarItemWrapper = styled.View(
  ({ hover, size, customPadding, isActive, activeBackground }) => ({
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    width: size ? size : "auto",
    height: size ? size : "auto",
    padding: customPadding ? customPadding : 30,
    backgroundColor: isActive ? activeBackground : colors.defaultBackground,
    ...(isActive
      ? {
          boxShadow:
            "inset -1px -1px 3px rgba(255, 255, 255, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)",
        }
      : hover
      ? {
          boxShadow:
            "1px 1px 5px rgba(94, 104, 121, 0.945), -1px -1px 4px #FFFFFF",
        }
      : {
          boxShadow:
            "1px 1px 3px rgba(94, 104, 121, 0.945), -1px -1px 4px #FFFFFF",
        }),
  })
)
