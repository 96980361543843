export function generateId (date = new Date(), random = random32) {
  return (
    prefix(date) +
    Array(4)
      .fill(0)
      .map(() => base62Encode(random()).padStart(6, '0'))
      .join('')
  )
}

const random32 = () => Math.floor(Math.random() * 56800235583)
const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split(
  ''
)

const base = charset.length

const base62Encode = function (num) {
  if (num === 0) {
    return '0'
  }
  let str = ''
  while (num > 0) {
    str = charset[num % base] + str
    num = Math.floor(num / base)
  }
  return str
}

function ts (t) {
  const d = t ? new Date(t) : new Date()
  return Math.floor(d.getTime() / 1000)
}

function prefix (date) {
  const timestamp = ts(date)

  // turn timestamp into 8-digit, base-62 encoded string
  return base62Encode(timestamp).padStart(8, '0')
}
