import React from 'react'
import {
  TouchableHighlight,
  View,
  Platform,
  ImageBackground,
  StyleProp,
  ViewStyle
} from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

type Props = {
  close: () => void
  style?: StyleProp<ViewStyle>
  variant?: 'normal' | 'light'
}

type State = {
  isHoveringCloseButton: boolean
}

export default class CloseButton extends React.Component<Props, State> {
  state = {
    isHoveringCloseButton: false
  }

  render() {
    return (
      <View style={this.props.style}>
        <TouchableHighlight
          activeOpacity={0.6}
          onPress={this.props.close}
          underlayColor='fff'
          {...Platform.select({
            web: {
              onMouseEnter: () => {
                this.setState({
                  isHoveringCloseButton: true
                })
              },
              onMouseLeave: () => {
                this.setState({
                  ...this.state,
                  isHoveringCloseButton: false
                })
              }
            }
          })}
        >
          <ImageBackground
            source={
              this.state.isHoveringCloseButton
                ? this.props.variant === 'light'
                  ? require('../src/images/close-modal-light-hover.png')
                  : require('../src/images/button-quit-hover.png')
                : this.props.variant === 'light'
                ? require('../src/images/close-modal-light.png')
                : require('../src/images/button-quit.png')
            }
            style={styles.closeImg}
          />
        </TouchableHighlight>
      </View>
    )
  }
}

const styles = EStyleSheet.create({
  closeImg: {
    width: 37.5,
    height: 35
  }
})
