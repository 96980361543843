import styled from '@emotion/native'
import React, {useEffect, useState} from 'react'
import {Animated} from 'react-native'
import {useTranslator} from "../translations/translate"

const filledHeart = require('../images/heart.png')
const emptyHeart = require('../images/empty_heart.png')

export const Score = ({max, current, win, mediaSmallMobile, width, level, height, mobile}) => {
  const {t} = useTranslator()
  const [numberOfEmptyHearts,] = useState(max - current)
  const [currentHearts, setCurrentHearts] = useState(current)
  const lineSize = max > 4 ? Math.ceil(max / 2) : max
  const [heartAnimationX,] = useState(new Animated.Value(0))
  const [heartAnimationY,] = useState(new Animated.Value(0))
  const [heartAnimationScale,] = useState(new Animated.Value(1))
  const [heartAnimationOpacity,] = useState(new Animated.Value(1))
  const currentHeartsArray = Array(currentHearts).fill({filledHeart})

  useEffect(() => {
    if (win) {
      Animated.sequence([
        Animated.timing(heartAnimationScale, {toValue: 3.5, duration: 500, useNativeDriver: true}),
      ]).start(() => waitTranslateOpacity)
      const waitTranslateOpacity = Animated.sequence([
        Animated.timing(heartAnimationOpacity, {toValue: 0, duration: 400, useNativeDriver: true}),
      ]).start(() => waitTranslateX)
      const waitTranslateX = Animated.sequence([
        Animated.timing(heartAnimationX, {toValue: 0, duration: 500, useNativeDriver: true}),
      ]).start(() => waitTranslateY)
      const waitTranslateY = Animated.sequence([
        Animated.timing(heartAnimationY, {toValue: -(height / 2), duration: 500, useNativeDriver: true}),
      ]).start()
    }
  }, [win])

  useEffect(() => {
    if (((max - current) > numberOfEmptyHearts)) {
      Animated.sequence([
        Animated.timing(heartAnimationScale, {toValue: 3.5, duration: 500, useNativeDriver: true}),
      ]).start(() => waitTranslateOpacity)
      const waitTranslateOpacity = Animated.sequence([
        Animated.timing(heartAnimationOpacity, {toValue: 0, duration: 400, useNativeDriver: true}),
      ]).start(() => waitTranslateX)
      const waitTranslateX = Animated.sequence([
        Animated.timing(heartAnimationX, {toValue: 1000, duration: 500, useNativeDriver: true}),
      ]).start(() => waitTranslateY)
      const waitTranslateY = Animated.sequence([
        Animated.timing(heartAnimationY, {toValue: -(height / 2), duration: 500, useNativeDriver: true}),
      ]).start(() => {
        heartAnimationX.setValue(0)
        heartAnimationY.setValue(0)
        heartAnimationOpacity.setValue(1)
        heartAnimationScale.setValue(1)
        setCurrentHearts(current)
      })
    }
  }, [current])

  const heartWidth = mediaSmallMobile ? 15 : 21
  const heartTotalWidth = heartWidth + 2 * heartPadding

  return <ScoreContainer lineSize={lineSize} heartTotalWidth={heartTotalWidth}>
    {
      Array(max).fill([]).map((_heart, index) => {


        return <WrapperHeart key={index + 'hearts'}>
          <ScoreHeart source={emptyHeart} heartWidth={heartWidth}/>
          {
            currentHeartsArray[index] ?
              <ScoreHeartRest source={filledHeart}
                              heartWidth={heartWidth}
                              style={index === currentHeartsArray.length - 1 || win ? {
                                transform: [
                                  {translateY: heartAnimationY},
                                  {translateX: heartAnimationX},
                                  {rotate: '180deg'},
                                  {scale: heartAnimationScale},
                                ],
                                opacity: heartAnimationOpacity
                              } : {
                                transform: [
                                  {translateY: 0},
                                  {translateX: 0},
                                  {rotate: '180deg'}
                                ]
                              }}/>
              : null
          }
        </WrapperHeart>
      })
    }
    {(level === '1' || level === 1) &&
    <TextWrapper width={width} mobile={mobile}>
      <Text mobile={mobile}>{t('app.header.firstExplanation')}</Text>
      <Text mobile={mobile}>{t('app.header.secondExplanation')}</Text>
    </TextWrapper>
    }
  </ScoreContainer>
}

const heartPadding = 3
const horizontalPadding = 20
const ScoreContainer = styled.View(({lineSize, heartTotalWidth}) => ({
  backgroundColor: "#FFBC73",
  borderRadius: 35,
  paddingLeft: horizontalPadding,
  paddingRight: horizontalPadding,
  paddingTop: 4,
  paddingBottom: 4,
  flexDirection: "row",
  flexWrap: "wrap",
  maxWidth: heartTotalWidth * lineSize + horizontalPadding * 2,
  transform: [{rotate: '180deg'}],
  zIndex: 5,
  position: 'relative'
}))

const ScoreHeart = styled.Image({
  position: 'relative',
  resizeMode: 'contain',
  height: 16,
  margin: heartPadding,
  transform: [{rotate: '180deg'}],
}, ({heartWidth}) => ({
  width: heartWidth,
}))

const ScoreHeartRest = styled(Animated.Image)({
  top: 0,
  resizeMode: 'contain',
  height: 16,
  margin: heartPadding,
  position: 'absolute',
  zIndex: 5,
}, ({heartWidth}) => ({
  width: heartWidth,
}))

const WrapperHeart = styled.View({
  position: 'relative',
})

const TextWrapper = styled.View(({width, mobile}) => ({
  marginTop: 15,
  width: mobile ? width*0.8 : width,
  justifyContent: 'center',
  textAlign: 'center',
  position: 'absolute',
  left: '50%',
  transform: [{translateX: '-50%'}, {rotate: '180deg'}],
  bottom: 55,
}))

const Text = styled.Text(({mobile}) => ({
  color: '#511346',
  fontSize: mobile ? 15 : 18
}))
