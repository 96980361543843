export const gameConfig = {
    3 : {
        levelDesktop : [
            [1, 1, 1],
        ],
        levelMobile : [
            [1, 1],
            [1]
        ],
        backgroundDesktop: require('./images/level1_desk.png'),
        backgroundMobile: require('./images/level1_mobil.png')
    },
    6 : {
        levelDesktop : [
            [1, 1, 1],
            [1, 1, 1]
        ],
        levelMobile : [
            [1, 1],
            [1, 1],
            [1, 1]
        ],
        backgroundDesktop: require('./images/level1_desk.png'),
        backgroundMobile: require('./images/level1_mobil.png')
    },
    8 : {
        levelDesktop : [
            [0, 1, 0],
            [1, 1, 1],
            [1, 1, 1],
            [0, 1, 0],
        ],
        levelMobile : [
            [0, 1, 1, 0],
            [1, 1, 1, 1],
            [0, 1, 1, 0],
        ],
        backgroundDesktop: require('./images/level2_desk.png'),
        backgroundMobile: require('./images/level2_mobil.png'),
    },
    10 : {
        levelDesktop : [
            [1, 1, 1],
            [1, 1, 1],
            [1, 1, 1],
            [0, 1, 0],
        ],
        levelMobile : [
            [1, 1, 1, 1],
            [1, 1, 1, 1],
            [0, 1, 1, 0],
        ],
        backgroundDesktop: require('./images/level2_desk.png'),
        backgroundMobile: require('./images/level2_mobil.png'),
    },
    12 : {
        levelDesktop : [
            [1, 1, 1],
            [1, 1, 1],
            [1, 1, 1],
            [1, 1, 1],
        ],
        levelMobile : [
            [1, 1, 1, 1],
            [1, 1, 1, 1],
            [1, 1, 1, 1],
        ],
        backgroundDesktop: require('./images/level3_desk.png'),
        backgroundMobile: require('./images/level3_mobil.png'),
    },
    14 : {
        levelDesktop : [
            [0, 1, 1, 1, 0],
            [1, 1, 1, 1, 1],
            [0, 1, 1, 1, 0],
            [0, 1, 1, 1, 0],
        ],
        levelMobile : [
            [0, 1, 0, 0],
            [1, 1, 1, 1],
            [1, 1, 1, 1],
            [1, 1, 1, 1],
            [0, 1, 0, 0],
        ],
        backgroundDesktop: require('./images/level2_desk.png'),
        backgroundMobile: require('./images/level2_mobil.png'),
    },
    16 : {
        levelDesktop : [
            [0, 1, 1, 1, 0],
            [1, 1, 1, 1, 1],
            [1, 1, 1, 1, 1],
            [0, 1, 1, 1, 0],
        ],
        levelMobile : [
            [0, 1, 1, 0],
            [1, 1, 1, 1],
            [1, 1, 1, 1],
            [1, 1, 1, 1],
            [0, 1, 1, 0],
        ],
        backgroundDesktop: require('./images/level4_desk.png'),
        backgroundMobile: require('./images/level4_mobil.png'),
    },
    18 : {
        levelDesktop : [
            [1, 1, 1, 1, 1],
            [1, 1, 1, 1, 1],
            [1, 1, 1, 1, 1],
            [0, 1, 1, 1, 0],
        ],
        levelMobile : [
            [1, 1, 1, 0],
            [1, 1, 1, 1],
            [1, 1, 1, 1],
            [1, 1, 1, 1],
            [1, 1, 1, 0],
        ],
        backgroundDesktop: require('./images/level2_desk.png'),
        backgroundMobile: require('./images/level2_mobil.png'),
    },
    20 : {
        levelDesktop : [
            [1, 1, 1, 1, 1],
            [1, 1, 1, 1, 1],
            [1, 1, 1, 1, 1],
            [1, 1, 1, 1, 1],
        ],
        levelMobile : [
            [1, 1, 1, 1],
            [1, 1, 1, 1],
            [1, 1, 1, 1],
            [1, 1, 1, 1],
            [1, 1, 1, 1],
        ],
        backgroundDesktop: require('./images/level5_desk.png'),
        backgroundMobile: require('./images/level5_mobil.png'),
    },
}

