import React from 'react'
import { Text, ImageBackground, View } from 'react-native'
// @ts-ignore
import Modal from 'modal-react-native-web'
import EStyleSheet from 'react-native-extended-stylesheet'
import Button from './button'
import CloseButton from './closebutton'
// @ts-ignore
import { T } from '../components/useLinkInText'

type ModalQuitProps = {
  closeModal: () => void
  onQuit: () => void
  visible: boolean
  translations: any
}

type ModalQuitState = {
  isHoveringCloseButtonModal: boolean
}

export default class ModalQuit extends React.Component<
  ModalQuitProps,
  ModalQuitState
> {
  constructor(props: ModalQuitProps) {
    super(props)
    this.state = {
      isHoveringCloseButtonModal: false
    }
  }

  render() {
    return (
      <Modal animationType='fade' transparent visible={this.props.visible}>
        <View style={styles.overlay}>
          <View style={styles.modal}>
            <ImageBackground
              source={require('../src/images/modal-result.png')}
              style={styles.imageBackground}
            >
              <CloseButton
                close={this.props.closeModal}
                variant='light'
                style={styles.close}
              />
              <Text style={styles.modalText}>
                <T text={this.props.translations.app.quitModal.text} />
              </Text>
              <Button
                style={styles.button}
                title={
                  ((
                    <T text={this.props.translations.app.quitModal.button} />
                  ) as unknown) as string
                }
                onPress={this.props.onQuit}
                textStyle={styles.buttonText}
                variant='modal'
              />
            </ImageBackground>
          </View>
        </View>
      </Modal>
    )
  }
}

const styles = EStyleSheet.create({
  overlay: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  modal: {
    margin: 'auto',
    '@media (min-width: 2560)': {
      width: 1512,
      height: 498
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      width: 1008,
      height: 332
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      width: 756,
      height: 249
    },
    '@media (min-width: 420) and (max-width: 1366)': {
      width: 504,
      height: 166
    },
    '@media (max-width: 420)': {
      width: 360,
      height: 118
    }
  },
  imageBackground: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  modalText: {
    textAlign: 'center',
    fontFamily: '$fontFamily',
    color: '$white',
    '@media (min-width: 2560)': {
      fontSize: 51,
      lineHeight: 54
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      fontSize: 34,
      lineHeight: 37
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      fontSize: 25.5,
      lineHeight: 28.5
    },
    '@media (max-width: 1366)': {
      fontSize: 18,
      lineHeight: 21
    }
  },
  close: {
    position: 'absolute',
    right: '5%',
    top: '10%'
  },
  button: {
    marginHorizontal: 'auto',
    marginTop: 20,
    alignItems: 'center'
  },
  buttonText: {
    color: '$textColor'
  }
})
