import { useState } from 'react'

export const useHovered = () => {
  const [hovered, setHovered] = useState(false)

  return [
    hovered,
    {
      onMouseEnter: () => setHovered(true),
      onMouseLeave: () => setHovered(false)
    }
  ]
}
