import React from 'react'
import { Text, ImageBackground, View } from 'react-native'
// @ts-ignore
import Modal from 'modal-react-native-web'
import EStyleSheet from 'react-native-extended-stylesheet'
// @ts-ignore
import LottieView from 'react-native-web-lottie'
// @ts-ignore
import { T } from '../components/useLinkInText'

type ModalResultProps = {
  closeModal: () => void
  win: boolean
  visible: boolean
  translations: any
}

type ModalResultState = {
  isHoveringCloseButtonModal: boolean
}

export default class ModalResult extends React.Component<
  ModalResultProps,
  ModalResultState
> {
  render() {
    if (this.props.visible && !this.props.win) {
      setTimeout(() => this.props.closeModal(), 2000)
    }
    return (
      <Modal animationType='fade' transparent visible={this.props.visible}>
        <View style={styles.overlay}>
          <View style={styles.modal}>
            <ImageBackground
              source={require('../src/images/modal-result.png')}
              style={styles.imageBackground}
            >
              <Text style={styles.modalText}>
                {this.props.win ? (
                  <T text={this.props.translations.app.resultModal.win} />
                ) : (
                  <T text={this.props.translations.app.resultModal.loose} />
                )}
              </Text>
            </ImageBackground>
          </View>
        </View>
        {this.props.win && (
          <LottieView
            source={require('../src/animations/victory-animation.json')}
            autoPlay
            loop={false}
            style={styles.animation}
            onAnimationFinish={() => this.props.closeModal()}
            speed={10}
          />
        )}
      </Modal>
    )
  }
}

const styles = EStyleSheet.create({
  overlay: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  modal: {
    margin: 'auto',
    '@media (min-width: 2560)': {
      width: 1512,
      height: 498
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      width: 1008,
      height: 332
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      width: 756,
      height: 249
    },
    '@media (min-width: 420) and (max-width: 1366)': {
      width: 504,
      height: 166
    },
    '@media (max-width: 420)': {
      width: 300,
      height: 98
    }
  },
  imageBackground: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  modalText: {
    textAlign: 'center',
    fontFamily: '$fontFamily',
    color: '$white',
    '@media (min-width: 2560)': {
      fontSize: 51,
      lineHeight: 54
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      fontSize: 34,
      lineHeight: 37
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      fontSize: 25.5,
      lineHeight: 28.5
    },
    '@media (max-width: 1366)': {
      fontSize: 16,
      lineHeight: 21
    }
  },
  button: {
    marginHorizontal: 'auto',
    marginTop: 20
  },
  buttonText: {
    color: '$textColor'
  },
  animation: {
    position: 'absolute',
    '@media (min-width: 2560)': {
      width: 1512 * 0.8,
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      margin: 0
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      width: 1008 * 0.8,
      marginTop: -880,
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      margin: 0
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      $width: 756 * 0.8,
      width: '$width',
      marginTop: -660,
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      margin: 0
    },
    '@media (min-width: 420) and (max-width: 1366)': {
      $width: 504 * 0.8,
      width: '$width',
      marginTop: -450,
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      margin: 0
    },
    '@media (max-width: 420)': {
      $width: 300 * 0.8,
      width: '$width',
      marginTop: -265,
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      margin: 0
    }
  }
})
