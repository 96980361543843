import React, { useEffect, useState } from 'react'
import { Score }                      from './Score'
import styled                         from '@emotion/native'

export const Header = ({max, current, level, win, mobile, mediaSmallMobile, width, height}) => {
  const [animationZIndex, setAnimationZIndex] = useState(false)

  useEffect(() => {
    setAnimationZIndex(true)
  }, [win])

  useEffect(() => {
    setTimeout(() => setAnimationZIndex(false), 2000)
  }, [animationZIndex])

  return <HeaderGame win={animationZIndex}>
    <LogoMemory source={require('../images/logo_memory.png')} mobile={mobile} mediaSmallMobile={mediaSmallMobile}/>
    <Score max={max} current={current} win={win} mediaSmallMobile={mediaSmallMobile} width={width} height={height} level={level} mobile={mobile}/>
  </HeaderGame>
}

const LogoMemory = styled.Image({
  marginTop: 30,
  marginBottom: 30
}, ({mediaSmallMobile}) => ({
  width: mediaSmallMobile ? 150 : 250,
  height: mediaSmallMobile ? 30 : 63,
}))

const HeaderGame = styled.View({
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 'auto',
}, ({win}) => ({
  zIndex: win ? 10 : 6,
}))


const LevelWrapper = styled.View({
  position: 'absolute',
}, ({mobile}) => ({
  top: mobile ? 40 : 20,
  right: mobile ? 10 : 70
}))
