import React, {useEffect, useRef, useState} from 'react'
import {Animated, Dimensions, StyleSheet, Vibration} from 'react-native'
import styled from '@emotion/native'
import {Description, MOBILE_BREAKPOINT} from './components/Description'
import {PictureCard, PictureCardBack} from './components/Pictures'
import {Header} from './components/Header'
import {gameConfig} from './gameConfig'
import {useScreenDimensions} from './Hooks/Hooks'
import {TranslationModuleProvider} from './translations/translate'

export const Game = ({
                       onClose,
                       level,
                       data,
                       contentTranslations,
                       lang,
                       timeStampStart
                     }) => {
  const levelConfig = gameConfig[data.length * 2]
  const [heartsTotalNumber] = useState(data.length * 2)
  const [heartsNumber, setHeartsNumber] = useState(data.length * 2)
  const [cardsArray, setCardsArray] = useState([])
  const [showDescription, setShowDescription] = useState(false)
  const [selected, setSelected] = useState([])
  const [matchedCards, setMatchedCards] = useState([])
  const [animationEnd, setAnimationEnd] = useState(false)
  const [lastDescription, setLastDescription] = useState({})
  const [cardColor, setCardColor] = useState('transparent')
  const [gameStatus, setGameStatus] = useState({gameOver: false, win: false})
  const [initLevel, setInitLevel] = useState({mapLevel: [], background: ''})
  const [lastDescriptionModalClosed, setLastDescriptionModalClosed] = useState(
    false
  )
  const [orientationScreen, setOrientationScreen] = useState('')
  const rootRef = useRef(null)
  const {width, height} = useScreenDimensions(rootRef)

  const [picturesTimeStamps, setPicturesTimeStamps] = useState({})
  const [lastTimeStampKey, setLastTimeStampKey] = useState(null)

  useEffect(() => {
    if (!Object.entries(lastDescription)?.length) return
    setPicturesTimeStamps((previousTimeStamps) => ({
      ...previousTimeStamps,
      [lastDescription?.key]: {
        description: lastDescription?.description,
        timeStampStart: new Date()
      }
    }))
    if (lastTimeStampKey) {
      setPicturesTimeStamps((previousTimeStamps) => ({
        ...previousTimeStamps,
        [lastTimeStampKey]: {
          ...previousTimeStamps?.[lastTimeStampKey],
          timeStampEnd: new Date()
        }
      }))
    }
    setLastTimeStampKey(lastDescription?.key)
  }, [lastDescription])

  useEffect(() => {
    if (width > MOBILE_BREAKPOINT) return setOrientationScreen('portrait')
    if (Dimensions.get('window').width < Dimensions.get('window').height) {
      setOrientationScreen('portrait')
    } else {
      setOrientationScreen('landscape')
    }
  }, [width, height])

  useEffect(() => {
    if (width >= MOBILE_BREAKPOINT) {
      setInitLevel({
        mapLevel: levelConfig.levelDesktop,
        background: levelConfig.backgroundDesktop,
      })
    } else {
      setInitLevel({
        mapLevel: levelConfig.levelMobile,
        background: levelConfig.backgroundMobile,
      })
    }
  }, [width])

  useEffect(() => {
    if (heartsNumber <= 0) {
      setPicturesTimeStamps((previousTimeStamps) => ({
        ...previousTimeStamps,
        [lastDescription?.key]: {
          ...previousTimeStamps?.[lastDescription?.key],
          timeStampEnd: new Date()
        }
      }))
      return setGameStatus((game) => ({...game, gameOver: true}))
    }
    if (
      (matchedCards.length === heartsTotalNumber &&
        width >= MOBILE_BREAKPOINT) ||
      (matchedCards.length === heartsTotalNumber && lastDescriptionModalClosed)
    ) {
      setPicturesTimeStamps((previousTimeStamps) => ({
        ...previousTimeStamps,
        [lastDescription?.key]: {
          ...previousTimeStamps?.[lastDescription?.key],
          timeStampEnd: new Date()
        }
      }))
      return setGameStatus((game) => ({...game, win: true}))
    }
  }, [heartsNumber, matchedCards, lastDescriptionModalClosed])

  useEffect(() => {
    const randomCardsArray = shuffle([[...data], [...data]].flat())
    const formatCardsArray = initLevel.mapLevel.map((line) => {
      return line.map((element) => {
        if (element === 0) return element

        return randomCardsArray.shift()
      })
    })
    setCardsArray(formatCardsArray)
  }, [initLevel])
  useEffect(() => {
    if (selected?.[0]?.name === selected?.[1]?.name) {
      setCardColor('#A9CA9F')
    } else {
      setCardColor('#E66555')
    }
    if (selected.length === 2 && animationEnd) {
      if (selected[0].name === selected[1].name) {
        setMatchedCards((cards) => [...cards, ...selected])
        setLastDescription({
          image: selected[0].image,
          description: selected[0].text,
          key: selected[0].key
        })
        setSelected([])
        setShowDescription(!showDescription)
        setAnimationEnd(false)
      } else {
        setSelected([])
        setAnimationEnd(false)
        setHeartsNumber((hearts) => hearts - 1)
      }
    }
  }, [selected, animationEnd])

  const animationEnded = () => {
    setAnimationEnd(true)
  }

  const [cardVibrateAnimation] = useState(new Animated.Value(0))

  useEffect(() => {
    if (selected.length === 2 && selected[0].name !== selected[1].name) {
      Animated.sequence([
        Animated.timing(cardVibrateAnimation, {
          toValue: 3,
          duration: 50,
          useNativeDriver: true,
        }),
        Animated.timing(cardVibrateAnimation, {
          toValue: -3,
          duration: 50,
          useNativeDriver: true,
        }),
        Animated.timing(cardVibrateAnimation, {
          toValue: 3,
          duration: 50,
          useNativeDriver: true,
        }),
        Animated.timing(cardVibrateAnimation, {
          toValue: -3,
          duration: 50,
          useNativeDriver: true,
        }),
        Animated.timing(cardVibrateAnimation, {
          toValue: 3,
          duration: 50,
          useNativeDriver: true,
        }),
        Animated.timing(cardVibrateAnimation, {
          toValue: -3,
          duration: 50,
          useNativeDriver: true,
        }),
        Animated.timing(cardVibrateAnimation, {
          toValue: 3,
          duration: 50,
          useNativeDriver: true,
        }),
        Animated.timing(cardVibrateAnimation, {
          toValue: 0,
          duration: 50,
          useNativeDriver: true,
        }),
      ]).start()
      setTimeout(() => {
        Vibration.vibrate(100)
        setTimeout(() => Vibration.vibrate(100), 100)
        setTimeout(() => Vibration.vibrate(100), 100)
      }, 200)
    }
  }, [selected])

  return (
    <MainWrapper
      orientationScreen={orientationScreen}
      width={width > height && width < MOBILE_BREAKPOINT ? height : width}
      height={width > height && width < MOBILE_BREAKPOINT ? width : height}
      ref={rootRef}
    >
      <TranslationModuleProvider lang={lang} moduleContent={contentTranslations}>
        <Container
          source={initLevel.background}
          width={width > height && width < MOBILE_BREAKPOINT ? height : width}
          height={width > height && width < MOBILE_BREAKPOINT ? width : height}
          orientationScreen={orientationScreen}
        >
          <Header
            max={heartsTotalNumber}
            current={heartsNumber}
            level={level}
            win={gameStatus.win}
            mobile={
              width > height && width < MOBILE_BREAKPOINT
                ? height < MOBILE_BREAKPOINT
                : width < MOBILE_BREAKPOINT
            }
            mediaSmallMobile={width > height ? height < 380 : width < 380}
            width={width}
            height={height}
          />
          <WrapperCardGrid
            width={width > height && width < MOBILE_BREAKPOINT ? height : width}
            height={width > height && width < MOBILE_BREAKPOINT ? width : height}
          >
            <WrapperCards
              width={width > height && width < MOBILE_BREAKPOINT ? height : width}
              topPosition={
                width > height
                  ? width / 2 - (cardsArray.length * 105) / 2
                  : height / 2 - (cardsArray.length * 105) / 2
              }
              mobile={
                width > height && width < MOBILE_BREAKPOINT
                  ? height < MOBILE_BREAKPOINT
                  : width < MOBILE_BREAKPOINT
              }
            >
              {cardsArray.map((line, j) => {
                return (
                  <RowCard key={j + 'map row'}>
                    {line.map((card, i) => {
                      if (!card.image) return
                      const key = `${j}-${i}`
                      return (
                        <WrapperAnimatedCard
                          key={i + 'cards'}
                          style={{
                            transform: [{translateX: cardVibrateAnimation}],
                          }}
                        >
                          <Card
                            firstGame={(level === '1' || level === 1)}
                            width={
                              width > height && width < MOBILE_BREAKPOINT
                                ? height
                                : width
                            }
                            height={
                              width > height && width < MOBILE_BREAKPOINT
                                ? width
                                : height
                            }
                            card={card}
                            handleSelect={() => {
                              if (
                                matchedCards.some(
                                  (matchedCard) =>
                                    matchedCard.name === card.name &&
                                    matchedCard.key === key
                                )
                              ) {
                                setLastDescription({
                                  image: card.image,
                                  description: card.text,
                                  key: key
                                })
                                return
                              }
                              if (
                                selected.some(
                                  (selected) =>
                                    selected.name === card.name &&
                                    selected.key === key
                                ) ||
                                animationEnd ||
                                selected.length === 2
                              )
                                return
                              setSelected((selected) => [
                                ...selected,
                                {
                                  key: key,
                                  name: card.name,
                                  image: card.image,
                                  text: card.text,
                                },
                              ])
                            }}
                            mobile={
                              width < 760 && width > height
                                ? height < 760
                                : width < 760
                            }
                            cardColor={cardColor}
                            cardsSelected={selected}
                            changeBackgroundColor={
                              selected.length === 2 &&
                              selected.some(
                                (selected) =>
                                  selected.name === card.name &&
                                  selected.key === key
                              )
                            }
                            animationEnded={animationEnded}
                            selected={
                              selected.some(
                                (selected) =>
                                  selected.name === card.name &&
                                  selected.key === key
                              ) ||
                              matchedCards.some(
                                (matchedCard) =>
                                  matchedCard.name === card.name &&
                                  matchedCard.key === key
                              )
                            }
                          />
                        </WrapperAnimatedCard>
                      )
                    })}
                  </RowCard>
                )
              })}
              {(lastDescription &&
                Object.entries(lastDescription).length &&
                width > MOBILE_BREAKPOINT) ||
              showDescription ? (
                <Description
                  onClose={() => {
                    setShowDescription((modal) => !modal)
                    if (matchedCards.length === heartsTotalNumber) {
                      setLastDescriptionModalClosed(true)
                    }
                    ;(gameStatus.win || gameStatus.gameOver) &&
                    !showDescription &&
                    onClose({
                      win: !!gameStatus.win,
                      heartsLeft: heartsNumber,
                      level: level,
                      timeStampStart: timeStampStart,
                      timeStampEnd: new Date(),
                      picturesTimeStamps: picturesTimeStamps
                    })
                  }}
                  image={lastDescription.image}
                  description={contentTranslations ? contentTranslations?.[lastDescription.description]
                    : lastDescription.description}
                  contentTranslations={contentTranslations}
                />
              ) : null}
            </WrapperCards>
          </WrapperCardGrid>
          {gameStatus.gameOver ? (
            <Description
              backgroundColor="#7B9D8C"
              onClose={() =>
                onClose({
                  win: !!gameStatus.win,
                  heartsLeft: heartsNumber,
                  level: level,
                  timeStampStart: timeStampStart,
                  timeStampEnd: new Date(),
                  picturesTimeStamps: picturesTimeStamps
                })
              }
              image={null}
              endedCard
              description={'app.endModal.failed'}
            />
          ) : gameStatus.win ? (
            <Description
              backgroundColor="#7B9D8C"
              onClose={() =>
                onClose({
                  win: !!gameStatus.win,
                  heartsLeft: heartsNumber,
                  level: level,
                  timeStampStart: timeStampStart,
                  timeStampEnd: new Date(),
                  picturesTimeStamps: picturesTimeStamps
                })
              }
              image={null}
              endedCard
              win
              description={'app.endModal.win'}
            />
          ) : null}
        </Container>
      </TranslationModuleProvider>
    </MainWrapper>
  )
}

const Card = ({
                card,
                firstGame,
                handleSelect,
                selected,
                cardsSelected,
                animationEnded,
                cardColor,
                changeBackgroundColor,
                mobile,
                width,
                height,
              }) => {
  const [
    {animation, frontInterpolate, frontOpacity, backInterpolate, backOpacity},
  ] = useState(initializeAnimation)
  const frontAnimatedStyle = {
    transform: [{rotateY: frontInterpolate}],
  }
  const backAnimatedStyle = {
    transform: [{rotateY: backInterpolate}],
  }
  useEffect(() => {
    Animated.spring(animation, {
      toValue: selected ? 180 : 0,
      friction: 8,
      tension: 100,
      restSpeedThreshold: 0.1,
      restDisplacementThreshold: 0.1,
    }).start(() => {
      if (cardsSelected.length === 2) {
        animationEnded()
      }
    })
  }, [selected])

  return (
    <WrapperCard onPress={handleSelect} mobile={mobile}>
      <PictureCard
        firstGame={firstGame}
        source={card.image}
        style={[backAnimatedStyle, {opacity: backOpacity}]}
        cardColor={cardColor}
        changeBackgroundColor={changeBackgroundColor}
        mobile={mobile}
        height={height}
        width={mobile ? (width > height ? height : width) : width}
      />
      <PictureCardBack
        style={[
          frontAnimatedStyle,
          {opacity: frontOpacity, position: 'absolute', top: 0,},
        ]}
        firstGame={firstGame}
        mobile={mobile}
        height={height}
        width={width > height ? height : width}
      >
        <BackImage source={require('./images/star_card.png')}/>
      </PictureCardBack>
    </WrapperCard>
  )
}

const initializeAnimation = () => {
  const animation = new Animated.Value(0)
  const frontInterpolate = animation.interpolate({
    inputRange: [0, 180],
    outputRange: ['0deg', '180deg'],
  })
  const backInterpolate = animation.interpolate({
    inputRange: [0, 180],
    outputRange: ['180deg', '360deg'],
  })
  const frontOpacity = animation.interpolate({
    inputRange: [89, 90],
    outputRange: [1, 0],
  })
  const backOpacity = animation.interpolate({
    inputRange: [89, 90],
    outputRange: [0, 1],
  })

  return {
    animation,
    frontOpacity,
    backOpacity,
    frontInterpolate,
    backInterpolate,
  }
}

const MainWrapper = styled.View({}, ({orientationScreen, width, height}) => ({
  height: orientationScreen === 'portrait' ? height : width,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const BackImage = styled.Image({
  width: '95%',
  height: '95%',
})

const WrapperAnimatedCard = styled(Animated.View)({})

const WrapperCardGrid = styled.View(
  {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 6,
  },
  ({width}) => ({
    width: width,
  })
)

const WrapperCards = styled.View(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ({width}) => ({
    width: width,
  })
)

const WrapperCard = styled.TouchableOpacity({}, ({mobile}) => ({
  margin: mobile ? 5 : 10,
}))

const Container = styled.ImageBackground(
  {
    ...StyleSheet.absoluteFillObject,
    resizeMode: 'cover',
  },
  ({orientationScreen, height, width}) =>
    orientationScreen === 'landscape'
      ? {
        transform: [{rotate: '-90deg'}],
        height: height,
        bottom: 0,
        position: 'relative',
      }
      : {
        width: width,
        height: height,
      }
)

const RowCard = styled.View({
  justifyContent: 'space-around',
  flexDirection: 'row',
  margin: 'auto',
})

function shuffle(array) {
  return array.sort(() => Math.random() - 0.5)
}
