import React from 'react'
import { Text, ScrollView } from 'react-native'
// @ts-ignore
import Modal from 'modal-react-native-web'
import EStyleSheet from 'react-native-extended-stylesheet'
import Button from './button'
import Logo from './logo'
// @ts-ignore
import { T } from '../components/useLinkInText'

type Props = {
  closeModal: () => void
  intro: string
  visible: boolean
  translations: any
  contentTranslations: any
}

export default class ModalPresentation extends React.Component<Props> {
  render() {
    return (
      <Modal animationType='fade' transparent visible={this.props.visible}>
        <ScrollView contentContainerStyle={styles.modal}>
          <Logo />
          <Text style={styles.textIntro}>
            <T
              text={
                this.props.contentTranslations
                  ? this.props.contentTranslations[this.props.intro]
                  : this.props.intro
              }
            />
          </Text>
          <Button
            style={styles.button}
            onPress={this.props.closeModal}
            title={
              ((
                <T text={this.props.translations.app.start} />
              ) as unknown) as string
            }
          />
        </ScrollView>
      </Modal>
    )
  }
}

const styles = EStyleSheet.create({
  modal: {
    backgroundColor: '$bgColor',
    width: '100%',
    height: '100%',
    alignItems: 'center'
  },
  textIntro: {
    marginTop: 25,
    width: '60%',
    textAlign: 'center',
    marginHorizontal: 'auto',
    fontFamily: '$fontFamily',
    color: '$textColor',
    fontSize: 18,
    lineHeight: 18,
    '@media (max-width: 420)': {
      width: '80%'
    }
  },
  button: {
    marginTop: 20,
    marginHorizontal: 'auto'
  }
})
