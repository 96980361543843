import React    from 'react'
import { View } from 'react-native'
import styled from '@emotion/native'

import Civiflouz          from '../../../assets/civiflouz.svg'
import CiviflouzRound     from '../../../assets/civiflouz-round.svg'
import Construction       from '../../../assets/construction.svg'
import Cross              from '../../../assets/cross.svg'
import Cubes              from '../../../assets/cubes.svg'
import Drop               from '../../../assets/drop.svg'
import InterrogationPoint from '../../../assets/interrogation-point.svg'
import Padlock            from '../../../assets/padlock.svg'
import RightArrow         from '../../../assets/right-arrow.svg'
import Settings           from '../../../assets/settings.svg'
import Tap                from '../../../assets/tap.svg'
import Thunder            from '../../../assets/thunder.svg'
import Mail               from '../../../assets/mail.svg'
import Password           from '../../../assets/password.svg'
import Player             from '../../../assets/player.svg'
import Info               from '../../../assets/info.svg'
import Smile              from '../../../assets/smile.svg'
import Search             from '../../../assets/search.svg'
import Building1          from '../../../assets/cityIcon/building1.svg'
import Building2          from '../../../assets/cityIcon/building2.svg'
import Building3          from '../../../assets/cityIcon/building3.svg'
import Square             from '../../../assets/teamIcon/square.svg'
import Triangle           from '../../../assets/teamIcon/triangle.svg'
import Round              from '../../../assets/teamIcon/round.svg'
import Star               from '../../../assets/rankIcon/star.svg'
import IndividualRank     from '../../../assets/rankIcon/individual-rank.svg'
import TeamRank           from '../../../assets/rankIcon/team-rank.svg'
import StarIcons          from '../../../assets/star.svg'
import Fragment           from '../../../assets/phase1/fragments.svg'
import Challenge          from '../../../assets/challenges/thunder.svg'
import Notification       from '../../../assets/challenges/notif.svg'
import VisibilityOn       from '../../../assets/visibility-icon.svg'
import VisibilityOff       from '../../../assets/visibility-off-icon.svg'

export const CiviflouzIcon = ({width, height, color, ...props}) => <View {...props}><Civiflouz width={width} height={height} fill={color}/></View>
export const CiviflouzRoundIcon = ({width, height, color, ...props}) => <View {...props}><CiviflouzRound width={width} height={height} fill={color}/></View>
export const ConstructionIcon = ({width, height, color, ...props}) => <View {...props}><Construction width={width} height={height} fill={color}/></View>
export const CrossIcon = ({width, height, color, ...props}) => <View {...props}><Cross width={width} height={height} fill={color}/></View>
export const CubesIcon = ({width, height, color, ...props}) => <View {...props}><Cubes width={width} height={height} fill={color}/></View>
export const DropIcon = ({width, height, color, ...props}) => <View {...props}><Drop width={width} height={height} fill={color}/></View>
export const InterrogationPointIcon = ({width, height, color, ...props}) => <View {...props}><InterrogationPoint width={width} height={height} fill={color}/></View>
export const PadlockIcon = ({width, height, color, ...props}) => <View {...props}><Padlock width={width} height={height} fill={color}/></View>
export const RightArrowIcon = ({width, height, color, ...props}) => <View {...props}><RightArrow width={width} height={height} fill={color}/></View>
export const SettingsIcon = ({width, height, color, ...props}) => <View {...props}><Settings width={width} height={height} fill={color}/></View>
export const TapIcon = ({width, height, color, ...props}) => <View {...props}><Tap width={width} height={height} fill={color}/></View>
export const ThunderIcon = ({width, height, color, ...props}) => <View {...props}><Thunder width={width} height={height} fill={color}/></View>
export const ThunderRoundIcon = ({width, height, color, wrapperColor, ...props}) => <RoundWrapper {...props} color={wrapperColor} width={width} height={height}><Thunder width={width * .8} height={height * .8} fill={color}/></RoundWrapper>
export const FragmentsIcon = ({width, height, color, ...props}) => <View {...props}><Fragment width={width} height={height} fill={color}/></View>
export const FragmentsRoundIcon = ({width, height, color, wrapperColor, ...props}) => <RoundWrapper {...props} color={wrapperColor} width={width} height={height}><Fragment width={width * .7} height={height * .7} fill={color}/></RoundWrapper>
export const MailIcon = ({width, height, color, ...props}) => <View {...props}><Mail width={width} height={height} fill={color}/></View>
export const PasswordIcon = ({width, height, color, ...props}) => <View {...props}><Password width={width} height={height} fill={color}/></View>
export const PlayerIcon = ({width, height, color, ...props}) => <View {...props}><Player width={width} height={height} fill={color}/></View>
export const InfoIcon = ({width, height, color, ...props}) => <View {...props}><Info width={width} height={height} fill={color}/></View>
export const UserIcon = ({width, height, color, ...props}) => <View {...props}><User width={width} height={height} fill={color}/></View>
export const SmileIcon = ({width, height, color, ...props}) => <View {...props}><Smile width={width} height={height} fill={color}/></View>
export const SearchIcon = ({width, height, color, ...props}) => <View {...props}><Search width={width} height={height} fill={color}/></View>
export const Building1Icon = ({width, height, color, ...props}) => <View {...props}><Building1 width={width} height={height} fill={color}/></View>
export const Building2Icon = ({width, height, color, ...props}) => <View {...props}><Building2 width={width} height={height} fill={color}/></View>
export const Building3Icon = ({width, height, color, ...props}) => <View {...props}><Building3 width={width} height={height} fill={color}/></View>
export const SquareIcon = ({width, height, color, ...props}) => <View {...props}><Square width={width} height={height} fill={color}/></View>
export const TriangleIcon = ({width, height, color, ...props}) => <View {...props}><Triangle width={width} height={height} fill={color}/></View>
export const RoundIcon = ({width, height, color, ...props}) => <View {...props}><Round width={width} height={height} fill={color}/></View>
export const StarIcon = ({width, height, color, ...props}) => <View {...props}><Star width={width} height={height} fill={color}/></View>
export const StarRoundIcon = ({width, height, color, wrapperColor, ...props}) => <RoundWrapper {...props} color={wrapperColor} width={width} height={height}><StarIcons width={.6 * width} height={.6 * height} fill={color}/></RoundWrapper>
export const IndividualRankIcon = ({width, height, color, ...props}) => <View {...props}><IndividualRank width={width} height={height} fill={color}/></View>
export const TeamRankIcon = ({width, height, color, ...props}) => <View {...props}><TeamRank width={width} height={height} fill={color}/></View>
export const ChallengeIcon = ({width, height, color, ...props}) => <View {...props}><Challenge width={width} height={height} fill={color}/></View>
export const NotificationIcon = ({width, height, color, ...props}) => <View {...props}><Notification width={width} height={height} fill={color}/></View>
export const DownArrowIcon = ({width, height, color, ...props}) => <View {...props} style={{transform: 'rotate(90deg)'}}><RightArrow width={width} height={height} fill={color}/></View>
export const VisibilityOffIcon = ({width, height, color, ...props}) => <View {...props}><VisibilityOff width={width} height={height} fill={color}/></View>
export const VisibilityOnIcon = ({width, height, color, ...props}) => <View {...props}><VisibilityOn width={width} height={height} fill={color}/></View>

const RoundWrapper = styled.View((({color, width, height}) =>({
  backgroundColor: color,
  borderRadius: '50%',
  width: width,
  height: height,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})))