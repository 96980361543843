import React from "react"
import styled from "@emotion/native"
import { RawDialog } from "./RawDialog"

export const Dialog = ({
  children,
  visible,
  closable,
  onRequestClose,
  noBackgroundShadow,
  ...props
}) =>
  !visible ? null : (
    <Wrapper {...props}>
      <RawDialog {...{ closable, onRequestClose, noBackgroundShadow }}>
        {children}
      </RawDialog>
    </Wrapper>
  )

const Wrapper = styled.View({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 10,
})
