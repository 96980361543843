import React from 'react'
import {View, Text, Animated, Easing} from 'react-native'
import Button from './button'
import {
  createDndContext,
  DragAndDropContext
} from '@civitime/react-native-easy-dnd/src/dragAndDropContext'
import {Answer, AnswerExtended, EndMessage} from '../types'
import TodoCell from './todocell'
import DoneCell from './donecell'
// @ts-ignore
import LottieView from 'react-native-web-lottie'
import EStyleSheet from 'react-native-extended-stylesheet'
import ModalResult from './modalresult'
import {nanoid} from 'nanoid'
// @ts-ignore
import {T} from './useLinkInText'

type Props = {
  answers: Answer[]
  onEnd: (endMessage: EndMessage) => void
  reRender: boolean
  level: number
  translations: any
  contentTranslations: any
}

type State = {
  attempt: number
  win: boolean
  point: number
  todo: (AnswerExtended | undefined)[]
  done: (AnswerExtended | undefined)[]
  showResultModal: boolean
  heartAnimations: Animated.Value[]
  dragMode: 'todo' | 'done'
  processing: boolean
  timeStampStart: Date
  timeStampEnd: Date
  infosModalsTimeStamps: {}
  updateTimeStampsInfos: (e: {}) => void
}

export default class Content extends React.Component<Props, State> {
  private dragAndDropContext: DragAndDropContext = createDndContext()
  private heartAnimationConfig = {
    toValue: 1,
    duration: 500,
    easing: Easing.linear,
    useNativeDriver: true
  }

  private doneCellsRef: (DoneCell | null)[] = []

  constructor(props: Props) {
    super(props)
    const todo = this._shuffleArray(props.answers).map((a, i) => {
      a.todoPosition = i
      return a
    })
    this.state = {
      processing: false,
      dragMode: 'todo',
      win: false,
      point: 0,
      showResultModal: false,
      attempt: 3,
      todo,
      timeStampStart: new Date(),
      timeStampEnd: null,
      done: new Array(this.props.answers.length).fill(undefined),
      heartAnimations: [
        new Animated.Value(0),
        new Animated.Value(0),
        new Animated.Value(0)
      ],
      infosModalsTimeStamps: {},
      updateTimeStampsInfos: (timeStamps) => this.updateInfosTimeStamps(timeStamps)
    }
  }

  updateInfosTimeStamps = (timeStamps) => {
    this.setState({
      ...this.state,
      infosModalsTimeStamps: {
        ...this.state.infosModalsTimeStamps,
        [timeStamps?.id]: {
          ...timeStamps,
          timeStampEnd: new Date()
        }
      }
    })
  }

  _shuffleArray = (input: AnswerExtended[]) => {
    const array = input.map((a) => Object.assign({}, a))
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * i)
      const temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    return array
  }

  _toggleZIndex = (source: 'todo' | 'done') => {
    this.setState({...this.state, dragMode: source})
  }

  _onMoveToTodoAnswer = (answer: AnswerExtended, newPosition: number) => {
    if (this.state.todo[newPosition] === undefined) {
      this.setState((state) => {
        if (answer.todoPosition !== undefined) {
          state.todo[answer.todoPosition] = undefined
          answer.todoPosition = newPosition
          state.todo[newPosition] = answer
        } else if (answer.donePosition !== undefined) {
          const prevPosition = answer.donePosition
          answer.donePosition = state.done[prevPosition] = undefined
          answer.todoPosition = newPosition
          state.todo[newPosition] = answer
        }
        return {
          done: state.done,
          todo: state.todo
        }
      })
    }
  }

  _onMoveToDoneAnswer = (answer: AnswerExtended, newPosition: number) => {
    if (this.state.done[newPosition] === undefined) {
      this.setState((state) => {
        if (answer.todoPosition !== undefined) {
          const prevPosition = answer.todoPosition
          answer.todoPosition = state.todo[prevPosition] = undefined
          answer.donePosition = newPosition
          state.done[newPosition] = answer
        } else if (answer.donePosition !== undefined) {
          state.done[answer.donePosition] = undefined
          answer.donePosition = newPosition
          state.done[newPosition] = answer
        }
        return {
          done: state.done,
          todo: state.todo
        }
      })
    }
  }

  _onAnimationEnd = (index: number) => {
    if (index + 1 < this.doneCellsRef.length) {
      this.doneCellsRef[index + 1]!.validate()
    } else {
      this._onValidateFinish()
    }
  }

  _onValidate = () => {
    this.setState({...this.state, processing: true})
    this.doneCellsRef[0]!.validate()
  }

  _onValidateFinish = () => {
    this.doneCellsRef.forEach((ref) => ref!.resetAnimation())
    const goodAnswer = this.state.done.filter((answer) => {
      return answer?.position === answer?.donePosition
    })
    if (goodAnswer.length === this.state.done.length) {
      this.setState({
        ...this.state,
        win: true,
        showResultModal: true,
        point: 100,
        processing: false,
        timeStampEnd: new Date()
      })
    } else {
      const attempt = this.state.attempt - 1
      Animated.timing(
        this.state.heartAnimations[attempt],
        this.heartAnimationConfig
      ).start(() => {
        if (attempt === 0) {
          this.setState({
            ...this.state,
            attempt,
            win: false,
            showResultModal: true,
            point: (goodAnswer.length / this.state.done.length) * 100,
            processing: false
          })
        } else {
          const done = this.state.done.map((answer) => {
            return answer?.position === answer?.donePosition
              ? answer
              : undefined
          })
          const todo = this.state.done
            .map((answer) => {
              return answer?.position !== answer?.donePosition
                ? answer
                : undefined
            })
            .map((answer) => {
              if (answer) {
                answer!.todoPosition = answer?.donePosition
                answer!.donePosition = undefined
              }
              return answer
            })
          this.setState({
            ...this.state,
            attempt,
            done,
            todo,
            processing: false
          })
        }
      })
    }
  }

  _onResultModalClose = () => {
    this.props.onEnd({
      point: this.state.point,
      heartsLeft: this.state.attempt,
      win: this.state.win,
      level: this.props.level,
      timeStampStart: this.state.timeStampStart,
      timeStampEnd: this.state.timeStampEnd,
      infosModalsTimeStamps: this.state.infosModalsTimeStamps
    })
  }

  render() {
    const sorted = this.props.answers.sort((a, b) => a.position - b.position)
    const {todo, done, attempt, processing} = this.state
    const {reRender} = this.props
    const {Provider} = this.dragAndDropContext
    return reRender ? (
      <View/>
    ) : (
      <Provider>
        <View style={styles.content}>
          <View
            style={[
              styles.column,
              this.state.dragMode === 'todo'
                ? styles.primaryColumn
                : styles.secondaryColumn
            ]}
          >
            <Text style={[styles.attemptText, styles.columnHeader]}>
              <T text={this.props.translations.app.list}/>
            </Text>
            {todo.map((answer, index) => (
              <TodoCell
                key={`todo-${index}-${answer?.position}`}
                customId={`todo-${index}-${nanoid()}`}
                answer={answer}
                trad={this.props?.contentTranslations?.answers || null}
                dragAndDropContext={this.dragAndDropContext}
                onMoveToTodoAnswer={(a) => this._onMoveToTodoAnswer(a, index)}
                onDragStart={() => this._toggleZIndex('todo')}
                updateInfosTimeStamps={(timeStamps) => this.updateInfosTimeStamps(timeStamps)}
              />
            ))}
          </View>
          <View
            style={[
              styles.column,
              this.state.dragMode === 'done'
                ? styles.primaryColumn
                : styles.secondaryColumn
            ]}
          >
            <View style={styles.columnHeader}>
              {attempt > 1 ? (
                <Text style={styles.attemptText}>
                  <T text={this.props.translations.app.left}/>
                  {this.state.attempt}
                  <T text={this.props.translations.app.attempts}/>
                </Text>
              ) : (
                <Text style={styles.attemptText}>
                  <T text={this.props.translations.app.left}/>
                  {this.state.attempt}
                  <T text={this.props.translations.app.attempt}/>
                </Text>
              )}
              <View style={styles.heartsAnimationGroup}>
                {attempt >= 1 && (
                  <LottieView
                    source={require('../src/animations/tries-heart-animation.json')}
                    style={styles.hearts}
                    autoPlay={false}
                    loop={false}
                    progress={this.state.heartAnimations[0]}
                  />
                )}
                {attempt >= 2 && (
                  <LottieView
                    source={require('../src/animations/tries-heart-animation.json')}
                    style={styles.hearts}
                    autoPlay={false}
                    loop={false}
                    progress={this.state.heartAnimations[1]}
                  />
                )}
                {attempt >= 3 && (
                  <LottieView
                    source={require('../src/animations/tries-heart-animation.json')}
                    style={styles.hearts}
                    autoPlay={false}
                    loop={false}
                    progress={this.state.heartAnimations[2]}
                  />
                )}
              </View>
            </View>
            {sorted.map((answer, index) => (
              <DoneCell
                key={`done-${index}-${answer.position}`}
                customId={`done-${index}-${nanoid()}`}
                correctAnswer={answer}
                currentAnswer={done[index]}
                dragAndDropContext={this.dragAndDropContext}
                onMoveToDoneAnswer={(a) => this._onMoveToDoneAnswer(a, index)}
                onAnimationEnd={() => this._onAnimationEnd(index)}
                ref={(ref) => (this.doneCellsRef[index] = ref)}
                onDragStart={() => this._toggleZIndex('done')}
                trad={this?.props?.contentTranslations?.answers}
                updateInfosTimeStamps={(timeStamps) => this.updateInfosTimeStamps(timeStamps)}
              />
            ))}
            <Button
              style={styles.testButton}
              disabled={
                processing || done.filter((a) => a === undefined).length > 0
              }
              title={
                ((
                  <T text={this.props.translations.app.test}/>
                ) as unknown) as string
              }
              onPress={this._onValidate}
            />
            <ModalResult
              closeModal={this._onResultModalClose}
              visible={this.state.showResultModal}
              win={this.state.win}
              translations={this.props.translations}
            />
          </View>
        </View>
      </Provider>
    )
  }
}

const styles = EStyleSheet.create({
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 420) and (orientation: portrait)': {
      flexDirection: 'column'
    }
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  primaryColumn: {
    zIndex: 2000
  },
  secondaryColumn: {
    zIndex: 1000
  },
  columnHeader: {
    '@media (min-width: 2560)': {
      minHeight: 144
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      minHeight: 96
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      minHeight: 72
    },
    '@media (max-width: 1366)': {
      minHeight: 48
    }
  },
  attemptText: {
    userSelect: 'none',
    color: '$textColor',
    fontFamily: '$fontFamily',
    textAlign: 'center',
    '@media (min-width: 2560)': {
      fontSize: 36,
      lineHeight: 39
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      fontSize: 24,
      lineHeight: 27
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      fontSize: 18,
      lineHeight: 21
    },
    '@media (max-width: 1366)': {
      fontSize: 13,
      lineHeight: 18
    },
    '@media (max-width: 550)': {
      display: 'none',
    }
  },
  heartsAnimationGroup: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  hearts: {
    '@media (min-width: 2560)': {
      width: 110,
      height: 98,
      margin: 13
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      width: 74,
      height: 66,
      margin: 9
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      width: 55,
      height: 49,
      margin: 6
    },
    '@media (max-width: 1366)': {
      width: 37,
      height: 33,
      margin: 4.5
    }
  },
  testButton: {
    marginVertical: 15
  }
})
