import React from 'react'
import {
  View,
  TouchableHighlight,
  ImageBackground,
  Platform
} from 'react-native'
import ModalHelp from './modalhelp'
import EStyleSheet from 'react-native-extended-stylesheet'

type State = {
  isModalHelpOpen: boolean
  isHoveringHelpButton: boolean
}

type FooterProps = {
  translations: any
}

export default class Footer extends React.Component<FooterProps, State> {
  state = { isModalHelpOpen: false, isHoveringHelpButton: false }

  _showModal = () => {
    this.setState({ ...this.state, isModalHelpOpen: true })
  }

  _hideModal = () => {
    this.setState({ ...this.state, isModalHelpOpen: false })
  }

  render() {
    return (
      <View style={styles.footer}>
        <TouchableHighlight
          style={styles.helpButton}
          activeOpacity={0.6}
          onPress={this._showModal}
          underlayColor='fff'
          {...Platform.select({
            web: {
              onMouseEnter: () => {
                this.setState({
                  isHoveringHelpButton: true
                })
              },
              onMouseLeave: () => {
                this.setState({
                  ...this.state,
                  isHoveringHelpButton: false
                })
              }
            }
          })}
        >
          <ImageBackground
            style={styles.helpImg}
            source={
              this.state.isHoveringHelpButton
                ? require('../src/images/button-help-hover.png')
                : require('../src/images/button-help.png')
            }
          />
        </TouchableHighlight>
        {this.state.isModalHelpOpen && (
          <ModalHelp
            closeModal={this._hideModal}
            translations={this.props.translations}
          />
        )}
      </View>
    )
  }
}

const styles = EStyleSheet.create({
  footer: {
    width: '100%',
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    '@media (min-width: 2560)': {
      flexBasis: 90
    },
    '@media (max-width: 2560)': {
      flexBasis: 60
    }
  },
  helpButton: {
    position: 'fixed',
    bottom: 10,
    '@media (min-width: 2560)': {
      right: 330
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      right: 220
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      right: 110
    },
    '@media (min-width: 420) and (max-width: 1366)': {
      right: 20
    },
    '@media (max-width: 420)': {
      right: 15
    }
  },
  helpImg: {
    '@media (min-width: 2560)': {
      width: 54,
      height: 84
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      width: 36,
      height: 56
    },
    '@media (max-width: 1920)': {
      width: 18,
      height: 28
    }
  }
})
