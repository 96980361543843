import styled from "@emotion/native"
import {Animated} from 'react-native'

export const PictureCard = styled(Animated.Image)({
    backgroundColor: "#FFA56B",
    borderRadius: 7,
    resizeMode: 'stretch',
    padding: 10,
}, ({cardColor, changeBackgroundColor, mobile, width, height, firstGame}) => ({
    borderColor: changeBackgroundColor ? cardColor : "#733050",
    borderWidth: 3,
    width: mobile ? (width/4.5) - 10  : firstGame ? (height/10) > 110 ? 110 : height/10 : 110,
    height: mobile ? (width/4.5) - 10 : firstGame ? (height/10) > 110 ? 110 : height/10 : 110,
}))

export const PictureCardBack = styled(Animated.View)({
    backgroundColor: "#FFA56B",
    borderRadius: 7,
    resizeMode: 'stretch',
    padding: 10,
}, ({cardColor, changeBackgroundColor, mobile, width, height, firstGame}) => ({
    borderColor: changeBackgroundColor ? cardColor : "#733050",
    borderWidth: 3,
    width: mobile ? (width/4.5) - 10  : firstGame ? (height/10) > 110 ? 110 : height/10 : 110,
    height: mobile ? (width/4.5) - 10 : firstGame ? (height/10) > 110 ? 110 : height/10 : 110,
}))
