import React from 'react'
import { DragAndDropContext } from '@civitime/react-native-easy-dnd/src/dragAndDropContext'
import { AnswerExtended } from '../types'
import { Animated, Image, ImageSourcePropType } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

type Props = {
  customId: string
  dragAndDropContext: DragAndDropContext
  onDrop: (payload: AnswerExtended) => void
  image: ImageSourcePropType
  activeImage?: ImageSourcePropType
}

export default class DropZone extends React.Component<Props> {
  render() {
    const { Droppable } = this.props.dragAndDropContext
    return (
      <Droppable
        customId={`droppable-${this.props.customId}`}
        onDrop={({ payload }) => this.props.onDrop(payload)}
      >
        {({ active, viewProps }) => {
          return (
            <Animated.View
              {...viewProps}
              style={[viewProps.style, styles.dropZone]}
            >
              <Image
                source={
                  active
                    ? this.props.activeImage || this.props.image
                    : this.props.image
                }
                style={styles.dropZoneImg}
                resizeMode='contain'
              />
            </Animated.View>
          )
        }}
      </Droppable>
    )
  }
}

const styles = EStyleSheet.create({
  dropZone: {
    zIndex: 2500,
    margin: 'auto',
    userSelect: 'none',
    '@media (min-width: 2560)': {
      width: 636,
      height: 144
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      width: 424,
      height: 96
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      width: 318,
      height: 72
    },
    '@media (max-width: 1366)': {
      width: 212,
      height: 48
    }
  },
  dropZoneImg: {
    flex: 1,
    width: undefined,
    height: undefined
  }
})
