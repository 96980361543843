import React, { useRef } from "react"
import { ScrollView } from "react-native"
import styled from "@emotion/native"
import { useScreenDimensions } from "../../hooks/useScreenDimensions"
import { ActionButton, CloseButton } from "../Button"
import { Title1 } from "../Texts"
import { colors } from "../../configs/colors"
import { MOBILE_HEADER_SIZE } from "../../configs/sizes"

export const DefaultModal = ({
  backgroundColor = colors.primary,
  title,
  color = colors.lightText,
  children,
  onPress,
  maxHeight,
  fixedHeight,
  withShadow,
  closeButtonColor = colors.secondary,
  noBorder,
  contentBackgroundColor,
  crossSize,
  borderRadius,
  modalResult,
  mobile,
  orientation,
  ...props
}) => {
  const root = useRef(null)
  const {width, height} = useScreenDimensions(root)

  return (
    <WrapperModal ref={root} {...props} {...{ withShadow }}>
      <Modal
        {...{
          width,
          height,
          backgroundColor,
          maxHeight,
          fixedHeight,
          withShadow,
          noBorder,
          contentBackgroundColor,
          borderRadius,
        }}
      >
        <TitleBar {...{ backgroundColor, withShadow, borderRadius }}>
          <Title {...{ color }}>{title || "Titre"}</Title>
          {onPress && (
            <CloseButtonDefaultModal
              color={closeButtonColor}
              iconColor="white"
              iconSize={crossSize / 2 || 15}
              size={crossSize || 30}
              noShadow
              {...{ onPress }}
            />
          )}
        </TitleBar>
        <ScrollView horizontal={false}>{children}</ScrollView>
      </Modal>
    </WrapperModal>
  )
}

const WrapperModal = styled.View(
  {
    justifyContent: "center",
    alignItems: "center",
    position: 'relative',
  },
  ({ withShadow }) =>
    withShadow && {
      boxShadow: "3px 3px 5px rgba(94, 104, 121, 0.945), -3px -3px 7px #FFFFFF",
      borderRadius: 5,
    }
)

const Modal = styled.View(
  {
    maxWidth: 845,
    flexDirection: "column",
  },
  ({
    width,
    height,
    backgroundColor,
    maxHeight,
    fixedHeight,
    noBorder,
    contentBackgroundColor,
    borderRadius,
  }) => ({
    backgroundColor: contentBackgroundColor || colors.defaultBackground,
    width: width * 0.95,
    maxHeight: maxHeight ? maxHeight : (height - MOBILE_HEADER_SIZE) * 0.85,
    minHeight: fixedHeight ? (height - MOBILE_HEADER_SIZE) * 0.85 : 200,
    borderColor: !noBorder && backgroundColor,
    borderWidth: !noBorder && 2,
    borderRadius: borderRadius || 5,
    overflow: 'hidden'
  }),
  ({ withShadow }) =>
    withShadow && {
      borderWidth: 0,
    }
)

const TitleBar = styled.View(
  {
    position: "relative",
    height: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: -1,
    marginRight: -1,
  },
  ({ backgroundColor, borderRadius }) => ({
    backgroundColor: backgroundColor,
    borderTopRightRadius: borderRadius || 0,
    borderTopLeftRadius: borderRadius || 0,
  }),
  ({ withShadow }) =>
    withShadow && {
      borderTopRightRadius: 5,
      borderTopLeftRadius: 5,
    }
)

const Title = styled(Title1)({
  textTransform: "uppercase",
})

export const CloseButtonDefaultModal = styled(CloseButton)(({ mobile }) => ({
  position: "absolute",
  right: mobile ? 2 : 5,
  top: 2,
  zIndex: 11
}))

export const BubbleHelp = ({
  hoverColor,
  children,
  mobile,
  title,
  ...props
}) => {
  const ref = useRef(null)
  const { width } = useScreenDimensions(ref)

  return (
    <WrapperBubble ref={ref} {...props}>
      <BubbleContent mobile={width < 760} width={width}>
        <BubbleTitle hoverColor={hoverColor}>
          <Title>{title}</Title>
        </BubbleTitle>
        {children}
      </BubbleContent>
      <BubbleNotch />
    </WrapperBubble>
  )
}

const WrapperBubble = styled.View({
  position: "absolute",
})

const BubbleContent = styled.View(
  {
    width: 250,
    minHeight: 250,
    boxShadow: "3px 3px 5px rgba(94, 104, 121, 0.945), -3px -3px 7px #FFFFFF",
    backgroundColor: colors.defaultBackground,
    borderRadius: 5,
  },
  ({ mobile, width }) => ({
    width: mobile ? width * 0.95 : 250,
  })
)

const BubbleTitle = styled.View(
  {
    paddingTop: 10,
    paddingBottom: 10,
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  ({ hoverColor }) => ({
    backgroundColor: hoverColor || colors.lightBlue,
  })
)

const BubbleNotch = styled.View({
  position: "absolute",
  bottom: -7,
  right: 20,
  width: 15,
  height: 15,
  backgroundColor: colors.defaultBackground,
  transform: [{ rotate: "45deg" }],
  boxShadow: "rgba(94, 104, 121, 0.745) 2px 2px 2px",
})

export const ModulesModal = ({
  noButton,
  backgroundColor,
  color,
  onPress,
  buttonText,
  children,
  actionButton,
  padding,
  mobile,
  pnj,
  ...props
}) => {
  return (
    <WrapperModulesModal {...props}>
      <ModulesModalStyle padding={padding} mobile={mobile}>
        {onPress && (
          <CloseButtonDefaultModal
            color={color}
            iconColor="white"
            iconSize={18}
            size={30}
            noShadow
            mobile={mobile}
            style={{ top: 20, right: 30 }}
            {...{ onPress }}
          />
        )}
        {children}
        {!noButton ? (
          <WrapperButton>
            <ActionButton
              style={{ width: "max-content" }}
              backgroundColor={color}
              color="white"
              onPress={actionButton}
              pnj={pnj}
              mobile={mobile}
            >
              {buttonText}
            </ActionButton>
          </WrapperButton>
        ) : null}
      </ModulesModalStyle>
    </WrapperModulesModal>
  )
}

const WrapperModulesModal = styled.View({
  alignItems: "center",
})

const ModulesModalStyle = styled(Modal)(({ padding, mobile }) => ({
  maxWidth: mobile ? '100%': 600,
  minWidth: 200,
  borderRadius: 18,
  border: "none",
  boxShadow:
    "inset -1px -1px 3px rgba(255, 255, 255, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)",
  paddingTop: 30,
  paddingRight: padding ? padding : 50,
  paddingLeft: padding ? padding : 50,
  paddingBottom: padding ? padding : 50,
}))

const WrapperButton = styled.View({
  alignItems: "center",
  paddingTop: 30,
  zIndex: 11,
})
