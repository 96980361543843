import React, { useRef, useState } from "react"
import styled from "@emotion/native"
import { brand as isMobileApp } from "expo-device"
import { SquareIcon, TriangleIcon, RoundIcon } from "../Icons"
import { useHovered } from "../../hooks/useHovered"
import { colors } from "../../configs/colors"
import { useScreenDimensions } from "../../hooks/useScreenDimensions"

const SelectionMonoIcon = ({
  onPress,
  size,
  active,
  activeColor = colors.secondary,
  children,
  ...props
}) => {
  return (
    <WrapperIcon {...{ onPress }}>
      <WrapperIconStyle {...{ size }} {...props}>
        {children}
        {active ? <ActiveBar {...{ activeColor }} /> : null}
      </WrapperIconStyle>
    </WrapperIcon>
  )
}

const ActiveBar = styled.View(
  {
    position: "absolute",
    bottom: -10,
    left: 0,
    width: "100%",
    height: 3,
    borderRadius: 3 / 2,
    marginTop: 10,
  },
  ({ activeColor }) => ({
    backgroundColor: activeColor,
  })
)

const WrapperIcon = styled.TouchableWithoutFeedback({})

const WrapperIconStyle = styled.View(
  {
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    backgroundColor: "transparent",
  },
  ({ size }) => ({
    width: size ? size : 50,
  })
)

export const SelectionBlazonButton = ({
  blazons,
  activeColor = colors.secondary,
  size,
  onPress,
}) => {
  const [activeItem, setActiveItem] = useState(null)

  return (
    <WrapperSelectionBlazon>
      {blazons.map((blazon, index) => {
        const Icon = blazon.icon
        const id = blazon.id
        const active = activeItem === id

        return (
          <WrapperBlazonIcon
            key={id}
            {...{ size, active, activeColor }}
            isFirst={index === 0}
            onPress={() => {
              onPress && onPress(id)
              setActiveItem(id)
            }}
          >
            <Icon
              width={size}
              height={size}
              color={active ? activeColor : colors.extraLightBackground}
            />
          </WrapperBlazonIcon>
        )
      })}
    </WrapperSelectionBlazon>
  )
}

const WrapperSelectionBlazon = styled.View({
  flexDirection: "row",
})

const WrapperBlazonIcon = styled(SelectionMonoIcon)(({ isFirst }) => ({
  marginLeft: isFirst ? 0 : 20,
}))

export const ColorsSelection = ({
  ballsColors,
  activeColor,
  size,
  onPress,
}) => {
  const [active, setActive] = useState(activeColor || null)
  const launchActive = (color) => setActive(color)
  return (
    <>
      {ballsColors.length ? (
        <WrapperColorsSelection
          width={
            (ballsColors && ballsColors.length * size) +
            (ballsColors && ballsColors.length * 10)
          }
        >
          {ballsColors.map((color, index) => {
            const isActive = active === color
            return (
              <SelectionMonoIcon
                key={index + "colors selection"}
                activeColor={colors.secondary}
                active={isActive}
                onPress={() => {
                  onPress && onPress(color)
                  launchActive(color)
                }}
              >
                <ColorBall color={color} size={size} isActive={isActive} />
              </SelectionMonoIcon>
            )
          })}
        </WrapperColorsSelection>
      ) : null}
    </>
  )
}

const ColorBall = styled.View(
  ({ color, size }) => ({
    borderRadius: size / 2,
    backgroundColor: color,
    width: size,
    height: size,
  }),
  ({ isActive }) =>
    isMobileApp
      ? isActive
        ? {
            shadowColor: colors.lightShadow,
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 0.8,
            shadowRadius: 2,
            elevation: 5,
          }
        : {
            shadowColor: colors.lightShadow,
            shadowOffset: {
              width: 0,
              height: 7,
            },
            shadowOpacity: 0.43,
            shadowRadius: 9.51,
            elevation: 15,
          }
      : isActive
      ? {
          boxShadow:
            "inset -1px -1px 3px rgba(255, 255, 255, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)",
        }
      : {
          boxShadow:
            "1px 1px 3px rgba(94, 104, 121, 0.945), -1px -1px 4px #FFFFFF",
        }
)

const WrapperColorsSelection = styled.View({}, ({ width }) => ({
  width: width,
  flexDirection: "row",
  justifyContent: "space-between",
}))

export const AccessorySelection = ({ Accessory, size, activeColor }) => {
  const [active, setActive] = useState(null)
  const launchActive = (accessoryName) => {
    if (active) return setActive(null)
    setActive(accessoryName)
  }
  const AccessorySvg = Accessory.svg

  return (
    <>
      {AccessorySvg ? (
        <WrapperAccessoriesSelection width={size}>
          <SelectionMonoIcon
            activeColor={colors.secondary}
            active={active === Accessory.name}
            onPress={() => launchActive(Accessory.name)}
            size={size}
          >
            <WrapperAccessory size={size}>
              {AccessorySvg ? (
                <AccessorySvg
                  width={"33%"}
                  fill={
                    active === Accessory.name ? activeColor : Accessory.color
                  }
                />
              ) : null}
              <AccessoryName>{Accessory.name}</AccessoryName>
            </WrapperAccessory>
          </SelectionMonoIcon>
        </WrapperAccessoriesSelection>
      ) : null}
    </>
  )
}

export const AccessoriesListSelection = ({
  Accessories,
  size,
  activeColor,
}) => {
  const [active, setActive] = useState(null)
  const launchActive = (accessoryName) => setActive(accessoryName)
  return (
    <>
      {Accessories.length ? (
        <WrapperAccessoriesSelection
          width={
            (Accessories && Accessories.length * size) +
            (Accessories && Accessories.length * 30)
          }
        >
          {Accessories.map((Accessory, index) => {
            const AccessorySvg = Accessory.svg
            return (
              <SelectionMonoIcon
                activeColor={colors.secondary}
                active={active === Accessory.name}
                onPress={() => launchActive(Accessory.name)}
                key={index + "accessoriesList"}
                size={size}
              >
                <WrapperAccessory size={size}>
                  <AccessorySvg
                    width={"33%"}
                    fill={
                      active === Accessory.name ? activeColor : Accessory.color
                    }
                  />
                  <AccessoryName>{Accessory.name}</AccessoryName>
                </WrapperAccessory>
              </SelectionMonoIcon>
            )
          })}
        </WrapperAccessoriesSelection>
      ) : null}
    </>
  )
}

const WrapperAccessory = styled.View(
  {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  ({ size }) => ({
    width: size,
  })
)

const AccessoryName = styled.Text({
  fontSize: 15,
  fontWeight: "bold",
  color: colors.text,
})

const WrapperAccessoriesSelection = styled(WrapperColorsSelection)({})

export const TextBarSelection = ({ inverted, children, onPress, active }) => {
  const [hovered, hoverProps] = useHovered()
  const invertedStyle = hovered ? !inverted : inverted
  return (
    <WrapperTouchableTextBarSelection onPress={onPress}>
      <WrapperTextBarSelection>
        <TextChildBarSelection
          inverted={invertedStyle}
          {...hoverProps}
          active={active}
        >
          {children}
        </TextChildBarSelection>
        {active ? <ActiveBar activeColor={colors.secondary} /> : null}
      </WrapperTextBarSelection>
    </WrapperTouchableTextBarSelection>
  )
}

const WrapperTextBarSelection = styled.View({
  padding: 10,
})

const TextChildBarSelection = styled.Text(
  {
    fontSize: 12,
    fontWeight: "bold",
  },
  ({ inverted, active }) => ({
    ...(active
      ? {
          color: colors.secondary,
        }
      : inverted
      ? {
          color: colors.secondary,
        }
      : {
          color: colors.text,
        }),
  })
)

const WrapperTouchableTextBarSelection = styled.TouchableWithoutFeedback({})

export const UseTextBarSelection = ({ text }) => {
  const [active, setActive] = useState(null)
  const launchActive = (text) => {
    if (active) return setActive(null)
    setActive(text)
  }

  return (
    <TextBarSelection onPress={launchActive} active={active}>
      {text}
    </TextBarSelection>
  )
}

export const UseMultiTextesBarSelection = ({
  texts,
  onPressArray,
  withoutActive,
  ...props
}) => {
  const [active, setActive] = useState(null)
  const launchActive = (text) => setActive(text)

  return (
    <>
      {texts.length ? (
        <WrapperMultiTextesSelection {...props}>
          {texts.map((text, index) => {
            const last = index === texts.length - 1
            return (
              <WrapperText key={index + "multiTextsSelectionBar"}>
                <TextBarSelection
                  onPress={() => {
                    !withoutActive && launchActive(text)
                    onPressArray &&
                      onPressArray.length === texts.length &&
                      onPressArray[index]()
                  }}
                  active={active === text && !withoutActive}
                >
                  {text}
                </TextBarSelection>
                {!last ? <LineSeparator /> : null}
              </WrapperText>
            )
          })}
        </WrapperMultiTextesSelection>
      ) : null}
    </>
  )
}

const WrapperMultiTextesSelection = styled.View({
  flexDirection: "row",
  justifyContent: "center",
})

const WrapperText = styled.View({
  flexDirection: "row",
})

const LineSeparator = styled.View({
  height: "100%",
  width: 1,
  borderLeftWidth: 1,
  borderLeftColor: colors.text,
})

export const MenuTextSelection = ({ items, backgroundColor, ...props }) => {
  const ref = useRef(null)
  const { width, mobile } = useScreenDimensions(ref)
  const [hovered, setHovered] = useState(null)
  const [active, setActive] = useState(null)

  return (
    <WrapperMenuTextSelection {...{ ref, width, mobile }} {...props}>
      {items.map((item, index) => {
        return (
          <WrapperTextSelection
            key={index + "Menu selection"}
            active={active === index}
            onMouseEnter={() => setHovered(index)}
            onMouseLeave={() => setHovered(null)}
            onPress={() => {
              //TODO: link buttons
              setActive(index)
            }}
          >
            <TextSelection hover={hovered === index} active={active === index}>
              {item?.text}
            </TextSelection>
          </WrapperTextSelection>
        )
      })}
    </WrapperMenuTextSelection>
  )
}

const WrapperMenuTextSelection = styled.View(
  ({ backgroundColor, width }) => ({
    width: width,
    maxWidth: 845,
    backgroundColor: backgroundColor || colors.defaultBackground,
    flexDirection: "row",
    justifyContent: "space-around",
  }),
  ({ mobile }) => ({
    flexDirection: mobile ? "column" : "row",
    ...(mobile
      ? {
          alignItems: "center",
        }
      : {
          justifyContent: "space-around",
        }),
  })
)

const WrapperTextSelection = styled.TouchableOpacity(
  {
    cursor: "pointer",
    paddingTop: 10,
    paddingBottom: 10,
  },
  ({ active }) => ({
    borderBottomColor: active ? colors.secondary : "transparent",
    borderBottomWidth: 3,
  })
)

const TextSelection = styled.Text(
  {
    fontFamily: "Raleway_500Medium",
    colors: colors.text,
  },
  ({ hover, active }) => ({
    fontWeight: hover || active ? "bold" : "500",
  })
)
