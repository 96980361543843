import React, { useState } from "react"
import styled              from "@emotion/native"
import { colors }          from "@civitime/library/storybook/configs/colors"
import { PressedInCard }   from "@civitime/library/storybook/stories/Cards"
import { View }            from "react-native"
import { UseLinkInText }               from '../useLinkInText'

export const QuestionsCards = ({
  question,
  sendResult,
  screenDimensions,
  mobile,
  contentTranslations
}) => {
  const [answerHovered, setAnswerHovered] = useState(null)

  return (
    <>
      <WrapperQuestionExplanation>
        <QuestionExplanation mobile={mobile}>
          <UseLinkInText text={contentTranslations ? contentTranslations[question?.question] : question?.question}/>
        </QuestionExplanation>
      </WrapperQuestionExplanation>
      <WrapperAnswers mobile={mobile}>
        {question?.answers?.map((answer, index) => {
          const questionNumber = answer.text.split('.')[1]
          return (
            <View
              key={index + "question"}
              onMouseEnter={() => setAnswerHovered(index)}
              onMouseLeave={() => setAnswerHovered(null)}
            >
              <TouchableAnswer
                onPress={() => {
                  sendResult(question.id, answer.goodAnswer)
                }}
              >
                <AnswerWrapper
                  hoverColor={colors.module}
                  screenDimensions={screenDimensions}
                  mobile={mobile}
                  customPadding={
                    mobile ? screenDimensions.height * 0.015 : null
                  }
                >
                  <TextAnswer mobile={mobile} isHover={answerHovered === index}>
                    <UseLinkInText text={contentTranslations ? contentTranslations[`answers.${questionNumber}`][answer.text] : answer.text}/>
                  </TextAnswer>
                </AnswerWrapper>
              </TouchableAnswer>
            </View>
          )
        })}
      </WrapperAnswers>
    </>
  )
}

const WrapperQuestionExplanation = styled.View({})

const QuestionExplanation = styled.Text(({ mobile }) => ({
  padding: mobile ? 10 : 15,
  fontSize: mobile ? 15 : 20,
  color: colors.dropShadow,
  fontWeight: "bold",
}))

const WrapperAnswers = styled.View({
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "space-between",
})

const AnswerWrapper = styled(PressedInCard)(({ screenDimensions, mobile }) => ({
  width: mobile ? screenDimensions.width * 0.9 : screenDimensions.width * 0.3,
  maxWidth: mobile ? "unset" : 415,
  marginTop: mobile ? "unset" : 20,
  marginBottom: mobile ? 7 : "unset",
  display: 'inline'
}))

const TextAnswer = styled.Text(({ mobile, isHover }) => ({
  fontSize: mobile ? 12 : 15,
  color: isHover ? "white" : colors.dropShadow,
  fontWeight: "bold",
}))

const TouchableAnswer = styled.TouchableWithoutFeedback({})
