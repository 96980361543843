export const colors = {
  primary: "#7600D5",
  secondary: "#1B39E2",
  secondaryLight: "#B3BEE4",

  logoColor: '#40C9A2',

  darkerBackground: "#C9CDDD",
  defaultBackground: "#DDE1E7",
  disabledBackground: "#BDC6D2",
  lightBackground: "#EEEDED",
  whisper: "#EEEEEE",
  extraLightBackground: "#FFFFFF",
  avatarBackground: "#DCE1E8",
  avatarItemBackground: "rgba(0, 204, 177, 0.3)",
  mapBackground: "#1D425C",
  highlightDanger: "#E3214C",

  text: "#4A4A4A",
  lightText: "#FFFFFF",
  white: "#F2F2F2",
  dropShadow: "#4A4A4A",
  cadetBlueL: "#A5AFBD",
  violet: "#7600D5",
  violetLight: "rgba(118, 0, 213, 0.1)",
  lightPink: "#E9E4E3",
  lightBlue: "#BFC6D1",
  lightShadow: "rgba(0, 0, 0, 0.25)",
  lightGrey: "#D3D3D3",
  module: "#FC6500",
  watermelon: "#EE5763",
  red: "#E20437",
  pulseOrange: "rgb(255, 144, 0)",
  inputBorder: "rgba(74, 74, 74, 0.4)",
  mobileButtonBorder: "rgba(144, 144, 144, 0.5)",
  vividBlue: "#0B7DD9",
  linkWater: "#BFC6D1",
  tooltip: "rgba(97, 97, 97, 0.9)",
  darkLightGrey: "#C3CDD9",
  timerSkeleton: "rgba(74, 74, 74, 0.8)",
  test: "rgba(74,74,74, .6)",

  skin: {
    peachPuff: "#FCD7B8",
    rose: "#E0A39A",
    cherokee: "#FCCC84",
    brownBramble: "#533724",
  },
  blazon: {
    salmon: "#D9856C",
    yellow: "#FFD237",
    cyan: "#68E6CF",
    purple: "#D078E2",
    orchid: "#6B86FF",
  },

  challenges: {
    loadingCard: "#C9D0E8",
    avatarHovered: "#798CEB",
    thunderPlaceholder: "#B4BECB",
    playerBackground: "#BBC4F6",
  },
}
