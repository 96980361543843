import React from 'react'
import { Text, ImageBackground, View } from 'react-native'
// @ts-ignore
import Modal from 'modal-react-native-web'
import EStyleSheet from 'react-native-extended-stylesheet'
import CloseButton from './closebutton'
// @ts-ignore
import { T } from '../components/useLinkInText'

type ModalAnswerExplanationProps = {
  closeModal: () => void
  text: string
  visible: boolean
}

type ModalAnswerExplanationState = {
  isHoveringCloseButtonModal: boolean
}

export default class ModalAnswerExplanation extends React.Component<
  ModalAnswerExplanationProps,
  ModalAnswerExplanationState
> {
  state = {
    isHoveringCloseButtonModal: false
  }

  render() {
    return (
      <Modal animationType='fade' transparent visible={this.props.visible}>
        <View style={styles.overlay}>
          <View style={styles.modal}>
            <ImageBackground
              source={require('../src/images/modal-info.png')}
              style={styles.imageBackground}
            >
              <Text style={styles.modalText}><T text={this.props.text}/></Text>
              <CloseButton
                close={this.props.closeModal}
                style={styles.closeBtn}
              />
            </ImageBackground>
          </View>
        </View>
      </Modal>
    )
  }
}

const styles = EStyleSheet.create({
  overlay: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  modal: {
    margin: 'auto',
    '@media (min-width: 2560)': {
      width: 1512,
      height: 588
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      width: 1008,
      height: 392
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      width: 756,
      height: 294
    },
    '@media (min-width: 767) and (max-width: 1366)': {
      width: 504,
      height: 196
    },
    '@media (min-width: 420) and (max-width: 767)': {
      width: 473,
      height: 184
    },
    '@media (max-width: 420)': {
      width: 360,
      height: 140
    }
  },
  imageBackground: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  modalText: {
    paddingHorizontal: 40,
    paddingVertical: 30,
    textAlign: 'center',
    fontFamily: '$fontFamily',
    color: '$textColor',
    '@media (min-width: 2560)': {
      fontSize: 51,
      lineHeight: 54
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      fontSize: 34,
      lineHeight: 37
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      fontSize: 25.5,
      lineHeight: 28.5
    },
    '@media (max-width: 1366)': {
      fontSize: 18,
      lineHeight: 21
    }
  },
  closeBtn: {
    position: 'absolute',
    '@media (min-width: 2560)': {
      top: 52.5,
      right: 52.5
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      top: 35,
      right: 35
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      top: 26.25,
      right: 26.25
    },
    '@media (min-width: 767) and (max-width: 1366)': {
      top: 17.5,
      right: 17.5
    },
    '@media (min-width: 420) and (max-width: 767)': {
      top: 15,
      right: 15
    },
    '@media (max-width: 420)': {
      top: 10,
      right: 10
    }
  }
})
