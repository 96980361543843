import React from 'react'
import { View, Animated, Easing, Platform } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
// @ts-ignore
import LottieView from 'react-native-web-lottie'

type Props = {}

type State = {
  logoAnimationProgress: Animated.Value
}

export default class Logo extends React.Component<Props, State> {
  state = { logoAnimationProgress: new Animated.Value(0) }

  _onMouseEnter = () => {
    Animated.timing(this.state.logoAnimationProgress, {
      toValue: 1,
      duration: 500,
      easing: Easing.linear,
      useNativeDriver: true
    }).start(() => {
      this.state.logoAnimationProgress.setValue(0)
    })
  }

  render() {
    return (
      <View
        style={styles.logoContainer}
        {...Platform.select({
          web: {
            onMouseEnter: this._onMouseEnter
          }
        })}
      >
        <LottieView
          source={require('../src/animations/logo-animation.json')}
          style={styles.logo}
          autoPlay={false}
          progress={this.state.logoAnimationProgress}
        />
      </View>
    )
  }
}

const styles = EStyleSheet.create({
  logoContainer: {
    marginHorizontal: 'auto',
    '@media (min-width: 2560)': {
      marginTop: 75
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      marginTop: 50
    },
    '@media (max-width: 1920)': {
      marginTop: 5
    }
  },
  logo: {
    '@media (min-width: 2560)': {
      marginTop: 75,
      width: 589.5
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      marginTop: 50,
      width: 393
    },
    '@media (min-width: 767) and (max-width: 1920)': {
      marginTop: 25,
      width: 250
    },
    '@media (max-width: 767)': {
      marginTop: 5,
      width: 125
    },
    height: '100%'
  }
})
