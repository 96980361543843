import React from 'react'
import { View } from 'react-native'
import { DragAndDropContext } from '@civitime/react-native-easy-dnd/src/dragAndDropContext'
import EStyleSheet from 'react-native-extended-stylesheet'
import { AnswerExtended } from '../types'
import Item from './item'
import DropZone from './dropzone'

type Props = {
  customId: string
  answer?: any
  dragAndDropContext: DragAndDropContext
  onMoveToTodoAnswer: (answer: AnswerExtended) => void
  onDragStart: () => void
  trad: any
  updateInfosTimeStamps: (e: {}) => void
}

type State = {
  drag: boolean
}

export default class TodoCell extends React.Component<Props, State> {
  state = { drag: false }

  _onDragStart = () => {
    this.props.onDragStart()
    this.setState({ drag: true })
  }

  _onDragEnd = () => {
    this.setState({ drag: false })
  }

  render() {
    return (
      <View style={[styles.todocell, this.state.drag ? styles.itemDrag : {}]}>
        {this.props.answer !== undefined ? (
          <Item
            customId={this.props.customId}
            answer={this.props.answer}
            trad={this.props.trad}
            dragAndDropContext={this.props.dragAndDropContext}
            onDragStart={this._onDragStart}
            onDragEnd={this._onDragEnd}
            updateInfosTimeStamps={(timeStamps) => this.props.updateInfosTimeStamps(timeStamps)}
          />
        ) : (
          <DropZone
            customId={this.props.customId}
            dragAndDropContext={this.props.dragAndDropContext}
            onDrop={this.props.onMoveToTodoAnswer}
            image={require('../src/images/item-placeholder-civitime.png')}
            activeImage={require('../src/images/item-holder-filled-civitime.png')}
          />
        )}
      </View>
    )
  }
}

const styles = EStyleSheet.create({
  itemDrag: {
    zIndex: 3500
  },
  todocell: {
    width: '100%',
    display: 'block'
  }
})
