import React, {useEffect, useState} from 'react'
import styled from "@emotion/native"
import {Question} from "./QuestionComponent"
import {Animated} from "react-native"

export const QuestionComponents = ({screenDimensions, mobile, data, result, sendResult, showResults, contentTranslations, updateQuestionsTimeStamps}) => {
  const [questionsPosition, setQuestionsPosition] = useState(0)
  const [questionsTranslate] = useState(new Animated.Value(questionsPosition || 0))
  const [questionComponentWidth, setQuestionComponentWidth] = useState(0)
  const [dimensions, setDimensions] = useState(() => ({...screenDimensions}))
  const [currentQuestion, setCurrentQuestion] = useState("")

  useEffect(() => {
    if (dimensions.width === screenDimensions.width) return
    const newQuestionsPosition = (screenDimensions.width + questionComponentWidth) * (Object.values(result || []).length)
    setQuestionsPosition(-newQuestionsPosition)
    setDimensions(screenDimensions)
  }, [screenDimensions])

  useEffect(() => {
    Animated.timing(questionsTranslate, {toValue: questionsPosition, duration: 500, useNativeDriver: true}).start()
  }, [questionsPosition])

  const launchNextQuestion = () => {
    setQuestionsPosition((position) => -(Math.abs(position) + dimensions.width + questionComponentWidth))
  }

  useEffect(() => {
    if(!data?.length) return
    setCurrentQuestion(data?.[0]?.id)
  }, [data])

  const onLayout = (event) => {
    setQuestionComponentWidth(event.nativeEvent.layout.width)
  }

  return <WrapperQuestionComponent screenDimensions={dimensions}
                                   questionsNumber={data?.length}
                                   mobile={mobile}
                                   style={{
                                     transform: [{translateX: questionsTranslate}]
                                   }}>
    {
      data?.map((question, index) => {
        return <Question screenDimensions={dimensions}
                         onLayout={onLayout}
                         position={index}
                         mobile={mobile}
                         question={question}
                         questionsNumber={data?.length}
                         sendResult={sendResult}
                         result={result}
                         showResults={showResults}
                         contentTranslations={contentTranslations}
                         launchNextQuestion={launchNextQuestion}
                         updateQuestionsTimeStamps={updateQuestionsTimeStamps}
                         currentQuestion={currentQuestion}
                         nextQuestion={data?.[index + 1]?.id || null}
                         updateCurrentQuestion={setCurrentQuestion}
                         key={index + "Questions"}/>
      })
    }
  </WrapperQuestionComponent>
}

const WrapperQuestionComponent = styled(Animated.View)(({screenDimensions, questionsNumber, mobile}) => ({
  position: 'absolute',
  flexDirection: 'row',
  top: mobile ? 'unset' : (screenDimensions.height - (screenDimensions.height * 0.66)) / 2,
  bottom: mobile ? 0 : 'unset',
  width: (screenDimensions.width) * questionsNumber,
  height: mobile ? screenDimensions.height * 0.90 : screenDimensions.height * 0.66,
}))
