import React from 'react'
import { View, Text } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import ModalQuit from './modalquit'
import CloseButton from './closebutton'
import Logo from './logo'
// @ts-ignore
import { T } from './useLinkInText'
type Props = {
  onQuit: () => void
  translations: any
}

type State = {
  showModalQuit: boolean
}

export default class Header extends React.Component<Props, State> {
  state = { showModalQuit: false }

  _showModal = () => {
    this.setState({ ...this.state, showModalQuit: true })
  }

  _hideModal = () => {
    this.setState({ ...this.state, showModalQuit: false })
  }

  _quitGame = () => {
    this._hideModal()
    this.props.onQuit()
  }

  render() {
    return (
      <View style={styles.header}>
        <Logo />
        <View style={styles.closeBtnGroup}>
          <CloseButton close={this._showModal} style={styles.closeBtn} />
          <Text style={styles.closeText}>
            <T text={this.props.translations.app.closeButton} />
          </Text>
        </View>
        <ModalQuit
          visible={this.state.showModalQuit}
          closeModal={this._hideModal}
          onQuit={this._quitGame}
          translations={this.props.translations}
        />
      </View>
    )
  }
}

const styles = EStyleSheet.create({
  header: {
    height: '100px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
    '@media (min-width: 1920)': {
      marginBottom: 45,
    },
  },
  closeBtnGroup: {
    position: 'absolute',
    flexDirection: 'column',
    height: 100,
    '@media (min-width: 2560)': {
      top: 40,
      right: 300
    },
    '@media (min-width: 1920) and (max-width: 2560)': {
      top: 26.5,
      right: 200
    },
    '@media (min-width: 1366) and (max-width: 1920)': {
      top: 10,
      right: 100
    },
    '@media (max-width: 1366)': {
      top: 10,
      right: 15
    }
  },
  closeBtn: {
    alignSelf: 'center'
  },
  closeText: {
    color: '$textColor',
    fontFamily: '$fontFamily'
  }
})
